import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {Router} from "@angular/router"
import { HotelAdminPanelService } from './../hotel-admin-panel.service';

@Component({
  selector: 'app-hotel-admin-detail',
  templateUrl: './hotel-admin-detail.component.html',
  styleUrls: ['./hotel-admin-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HotelAdminDetailComponent implements OnInit {
  hotelUserData = {};
  currentActiveRoute: string

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private hotelAdminPanelService: HotelAdminPanelService
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/admin/design-common.js");
    this.currentActiveRoute = this.router.url;
    this.getHotelSessionDetail();
  }

  public loadJsFile(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('body')[0].appendChild(node);  
  }  

  getHotelSessionDetail(){
    let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
    if(response['status'] == 1){
      this.hotelUserData = response['hotelUserData']
    }
  };

}