import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from "@angular/router"
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { StaffFrontDeskPanelService } from '../staff-front-desk-panel.service';

@Component({
  selector: 'app-staff-forgot-password',
  templateUrl: './staff-forgot-password.component.html',
  styleUrls: ['./staff-forgot-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StaffForgotPasswordComponent implements OnInit {
  subscription: Subscription;
  emailOrMobileNumber;
  emailOrPasswordErrorMessage: string = ''
  loginErrorMessage: string;
  isUserLogin: boolean = false;
  userOTP: string = '';
  password: string = '';
  confirmPassword: string = '';
  currentFormStep: string = 'STEP1'
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private staffFrontDeskPanelService: StaffFrontDeskPanelService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/frontdesk/design-common.js");
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  validateEmailOrMobileNumber(){
    this.emailOrPasswordErrorMessage = '';
    if(isNaN(this.emailOrMobileNumber) == true) {
      let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if(reg.test(this.emailOrMobileNumber) == false) {
          this.emailOrPasswordErrorMessage = 'Please enter a valid email'
      }
      else{
        this.emailOrPasswordErrorMessage = '';
      }
    }
    else if (isNaN(this.emailOrMobileNumber) == false) {
      let reg_mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
      if (reg_mobile.test(this.emailOrMobileNumber) == false) {
        this.emailOrPasswordErrorMessage = 'Please enter a valid mobile no.'
      }
      else{
        this.emailOrPasswordErrorMessage = '';
      }
    }
  }

  validateUserDetail(){
    this.isUserLogin = true;
    this.staffFrontDeskPanelService.forgotPasswordGenerateOTP({'email_or_mobile': this.emailOrMobileNumber})
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.isUserLogin = false;
            this.toastr.success(response['msg'])
            this.currentFormStep = 'STEP2'
          }
          else{
            this.isUserLogin = false;
            if(response['msg'] && response['status'] == 0){
              this.toastr.error('mobile number or email does not exist')
            }
          }
        },
        error => {
          this.isUserLogin = false;
          console.log(error);
        }
      );
  };

  verifyOTPAndUpdatePassword(){
    let data = {
      'email_or_mobile': this.emailOrMobileNumber,
      'cell_otp': this.userOTP
    }
    if(this.password != ''){
      data['new_password'] = this.password;
    }
    this.staffFrontDeskPanelService.forgotPasswordVerifyOTP(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.toastr.success(response['msg'])
            if(this.password == ''){
              this.isUserLogin = false;
              this.currentFormStep = 'STEP3';
            }
            else{
              this.isUserLogin = true;
              setTimeout(()=>{
                this.router.navigate(['/front-desk/login'])
              }, 2000)
            }
          }
          else{
            this.isUserLogin = false;
            if(response['msg'] && response['status'] == 0){
              this.toastr.error(response['msg'])
            }
          }
        },
        error => {
          this.isUserLogin = false;
          console.log(error);
        }
      );
  };

}
