import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import { StaffFrontDeskPanelService } from '../../staff-front-desk-panel.service';

@Component({
  selector: 'app-staff-panel-top-navigation',
  templateUrl: './staff-panel-top-navigation.component.html',
  styleUrls: ['./staff-panel-top-navigation.component.css']
})
export class StaffPanelTopNavigationComponent implements OnInit {

  hotelStaffUserData = {};
  constructor(
    private router: Router,
    private staffFrontDeskPanelService: StaffFrontDeskPanelService
  ) { }

  ngOnInit() {
    this.getHotelSessionDetail();
  }

  getHotelSessionDetail(){
    let response = this.staffFrontDeskPanelService.getHotelStaffUserSessionData()
    if(response['status'] == 1){
      this.hotelStaffUserData = response['hotelStaffUserData']
    }
  };

  logOut(){
    let response = this.staffFrontDeskPanelService.clearHotelStaffUserSessionData()
    if(response['status'] == 1){
      this.router.navigate(['/front-desk/login'])
    }
  };

}
