import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  ViewEncapsulation
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router"
import { HotelAdminPanelService } from '../hotel-admin-panel.service';

@Component({
  selector: 'app-hotel-availability',
  templateUrl: './hotel-availability.component.html',
  styleUrls: ['./hotel-availability.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HotelAvailabilityComponent implements OnInit {
  time = {hour: 13, minute: 30};
  meridian = true;
  availableTo:object = {hour: 0, minute: 0};
  availableFrom:object= {hour: 0, minute: 0};
  isHotelAvailability: boolean = false;
  errorMessage: string = '';
  errorObj = {};
  currentHotelAdminUserData = {};
  constructor(
    private toastr: ToastrService,
    private hotelAdminPanelService: HotelAdminPanelService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/admin/design-common.js");
    this.getHotelAdminUserSessionDetail();
  }

  public loadJsFile(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('body')[0].appendChild(node);  
  }

  getHotelAdminUserSessionDetail(){
    let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
    if(response['status'] == 1){
      this.currentHotelAdminUserData = response['hotelUserData']
      this.getHotelAvailabilityDetails();
    }
  };

  getHotelAvailabilityDetails(){
    this.hotelAdminPanelService.getHotelAvailabilityData({'hotel_id': this.currentHotelAdminUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            let data = response['data']
            this.isHotelAvailability = data['is_availability_enabled']
            if(data && 'available_from' in data){
              this.availableFrom = data['available_from']
            }
            if(data && 'available_to' in data){
              this.availableTo = data['available_to']
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  isHotelAvailabilityEnabled(event) {
    if (event.target.checked) {
      this.isHotelAvailability = true;
    }
    else{
      this.isHotelAvailability = false;
    }
    this.updateHotelAvailabilityDetails();
  }

  updateHotelAvailabilityDetails(){
    let data = {}
    if(this.availableTo && this.availableFrom){
      data['available_to'] = this.availableTo
      data['available_from'] = this.availableFrom
      data['is_availability_enabled'] = this.isHotelAvailability
      data['hotel_id'] = this.currentHotelAdminUserData['hotel_id']
      this.hotelAdminPanelService.updateHotelAvailabilityData(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    }

  };

 

}
