import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  ViewEncapsulation
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Router } from "@angular/router"
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HotelAdminPanelService } from '../hotel-admin-panel.service';

@Component({
  selector: 'app-staff-management',
  templateUrl: './staff-management.component.html',
  styleUrls: ['./staff-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StaffManagementComponent implements OnInit {
  @ViewChild('staffCreateModel', { read: ElementRef, static:false }) public staffCreateModel: ElementRef;
  @ViewChild('staffDetailModel', { read: ElementRef, static:false }) public staffDetailModel: ElementRef;
  @ViewChild('staffUpdateModel', { read: ElementRef, static:false }) public staffUpdateModel: ElementRef;
  @ViewChild('deparmentCreateModel', { read: ElementRef, static:false }) public deparmentCreateModel: ElementRef;
  @ViewChild('deparmentUpdateModel', { read: ElementRef, static:false }) public deparmentUpdateModel: ElementRef;
  @ViewChild('deparmentDeleteModel', { read: ElementRef, static:false }) public deparmentDeleteModel: ElementRef;
  @ViewChild('staffRoleCreateModel', { read: ElementRef, static:false }) public staffRoleCreateModel: ElementRef;
  @ViewChild('staffRoleUpdateModel', { read: ElementRef, static:false }) public staffRoleUpdateModel: ElementRef;
  @ViewChild('staffRoleDeleteModel', { read: ElementRef, static:false }) public staffRoleDeleteModel: ElementRef;
  @ViewChild('staffAssignmentDetailModel', { read: ElementRef, static:false }) public staffAssignmentDetailModel: ElementRef;
  @ViewChild('staffAssignmentCreateModel', { read: ElementRef, static:false }) public staffAssignmentCreateModel: ElementRef;
  @ViewChild('staffEndAssignmentModel', { read: ElementRef, static:false }) public staffEndAssignmentModel: ElementRef;
  webSocket: WebSocketSubject<any>;
  messages: Observable<any>;
  subscription: Subscription;
  errorMessage: string = '';
  errorObj = {};
  currentHotelAdminUserData = {};
  hotelStaffList = [];
  hotelStaffRoleList = [];
  hotelDepartmentList = [];
  hotelRoomServiceList = [];
  hotelFNBOutletList = [];
  countryList = [];
  stateList = [];
  cityList = [];
  countryID: number = null;
  stateID: number = null;
  cityID: number = null;
  firstName: string = '';
  middleName: string = '';
  lastName: string = '';
  employeeCode: string = ''
  isActive: boolean = true;
  isFrontDeskUser: boolean = false;
  isAvailable: boolean = true;
  email: string = '';
  cellNumber: string = '';
  cellNumber2: string = '';
  password: string = '';
  confirmPassword: string = '';
  addressLine1: string = '';
  addressLine2: string = '';
  staffCountryID: number = null;
  staffStateID: number = null;
  staffCityID: number = null;
  pincode: number = null;
  departmentID: Number = null;
  managerID: Number = null;
  outletID: number = null;
  staffRoleID: Number = null;
  currentSelectedManager = {};
  currentStaffMemberDetail:object = {};
  departmentName: string = null;
  departmentShortName: string = null;
  currentDepartmentObj: object = {};
  staffRoleName: string = null;
  staffRoleShortName: string = null;
  staffRoleDescription: string = null;
  staffRoleDepartmentID: number = null;
  currentStaffRoleObj:object = {};
  staffAssignmentRoleID: number = null;
  staffAssignmenDepartmentID: number = null;
  staffAssignmenManagerID: number = null;
  currentSelectedDepartmentObj = {};
  isStaffCellNumberExits: boolean = false;
  isStaffEmailExits: boolean = false;
  isStaffEmployeeCodeExits: boolean = false;
  currentTab:string = '';
  constructor(
    private toastr: ToastrService,
    private hotelAdminPanelService: HotelAdminPanelService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/admin/design-common.js");
    this.getHotelAdminUserSessionDetail();
    this.webSocket = webSocket(environment.webSoketBaseURL + '/hotel-chain/');
    this.webSocket.asObservable().subscribe(dataFromServer => {
      if(dataFromServer['event'] == 'change_staff_is_available_status' && 
        dataFromServer['data'] && 
        dataFromServer['data']['hotel'] == this.currentHotelAdminUserData['hotel_id']){
        this.getHotelStaffList();
      }
    });
  };

  public loadJsFile(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('body')[0].appendChild(node);  
  }

  getHotelAdminUserSessionDetail(){
    let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
    if(response['status'] == 1){
      this.currentHotelAdminUserData = response['hotelUserData']
      this.getHotelStaffList();
      this.getHotelDepartmentList();
      this.getHotelRoomServiceList();
      this.getHotelFNBOutletList();
      this.getCountryList();
    }
  };

  getHotelStaffList(){
    this.hotelStaffList = [];
    let data = {'hotel_id': this.currentHotelAdminUserData['hotel_id']}
    if(this.currentHotelAdminUserData && 'is_super_admin' in this.currentHotelAdminUserData && 
      this.currentHotelAdminUserData['is_super_admin'] == false && 
      'department_name' in this.currentHotelAdminUserData && 
      (this.currentHotelAdminUserData['department_name'] == 'Engineering' || 
      this.currentHotelAdminUserData['department_name'] == 'House Keeping')){
        data['department_id'] = this.currentHotelAdminUserData['department_id']
    }
    else if(this.currentHotelAdminUserData && 'is_super_admin' in this.currentHotelAdminUserData && 
      this.currentHotelAdminUserData['is_super_admin'] == false && 
      'department_name' in this.currentHotelAdminUserData && 
      this.currentHotelAdminUserData['department_name'] == 'Food & Beverages'){
        data['department_id'] = this.currentHotelAdminUserData['department_id']
    }
    this.hotelAdminPanelService.getHotelStaffList(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.hotelStaffList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelDepartmentList(){
    this.hotelDepartmentList = [];
    this.hotelAdminPanelService.getHotelDepartmentList({'hotel_id': this.currentHotelAdminUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.hotelDepartmentList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelStaffRoleList(departmentID){
    this.outletID = null;
    this.currentSelectedDepartmentObj = {};
    let data = {
      'hotel_id': this.currentHotelAdminUserData['hotel_id']
    }
    if(departmentID != ''){
      data['department_id'] = departmentID;
      this.currentSelectedDepartmentObj = this.hotelDepartmentList.find(o => o.id == departmentID);

    }
    this.staffAssignmentRoleID = null;
    this.staffRoleID = null;
    if(this.currentStaffMemberDetail && 'hotel_staff_role_id' in this.currentStaffMemberDetail){
      this.currentStaffMemberDetail['hotel_staff_role_id'] = null;
    }
    this.hotelStaffRoleList = [];
    this.hotelAdminPanelService.getHotelStaffRoleList(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.hotelStaffRoleList = response['data']
            if(this.currentTab =='DepartmentAssignments'){
              this.getHotelRoomServiceList();
              this.getHotelFNBOutletList();
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelFNBOutletList() {
    this.hotelFNBOutletList  = []
    let hotelFNBOutletList  = []
    this.hotelAdminPanelService.getHotelFNBOutletList({'hotel_id': this.currentHotelAdminUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            // this.hotelFNBOutletList = response['data']
            let currentOutletObj = this.hotelDepartmentList.find(o => o.name == 'Food & Beverages');
            response['data'].forEach(function (outletObj: object) {
              if(currentOutletObj && 'id' in currentOutletObj){
                outletObj['outlet_department'] = currentOutletObj['id']
              }
              else{
                outletObj['outlet_department'] = null;
              }
              outletObj['outlet_staff_role'] = null;
              hotelFNBOutletList.push(outletObj)
            })
            this.hotelFNBOutletList = hotelFNBOutletList;
            if(this.hotelFNBOutletList && this.hotelFNBOutletList.length > 0){
              this.hotelFNBOutletList.forEach( (hotelFNBOutletObj) => {
                if(hotelFNBOutletObj['hotel_department']){
                  if(this.hotelStaffRoleList && this.hotelStaffRoleList.length > 0){
                    let roleList = []
                    this.hotelStaffRoleList.forEach( (hotelStaffRoleObj) => {
                      if(hotelStaffRoleObj['department_id'] == hotelFNBOutletObj['hotel_department']){
                        roleList.push(hotelStaffRoleObj)
                      }
                    });
                    hotelFNBOutletObj['hotelStaffRoleList'] = roleList;
                  }
                }
              });
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelRoomServiceList(){
    this.hotelRoomServiceList = [];
    this.hotelAdminPanelService.getHotelRoomServiceList({'hotel_id': this.currentHotelAdminUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.hotelRoomServiceList = response['data']
            this.hotelRoomServiceList.forEach( (hotelRoomServiceObj) => {
              if(hotelRoomServiceObj['hotel_department'] && hotelRoomServiceObj['hotel_staff_role']){
                if(this.hotelStaffRoleList && this.hotelStaffRoleList.length > 0){
                  let roleList = []
                  this.hotelStaffRoleList.forEach( (hotelStaffRoleObj) => {
                    if(hotelStaffRoleObj['department_id'] == hotelRoomServiceObj['hotel_department']){
                      roleList.push(hotelStaffRoleObj)
                    }
                  });
                  hotelRoomServiceObj['hotelStaffRoleList'] = roleList;
                }
              }
            });
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  updateHotelStaffRoleList(roomSericeObject){
    if(roomSericeObject['hotel_department']){
      roomSericeObject['hotel_staff_role'] = null
      if(this.hotelStaffRoleList && this.hotelStaffRoleList.length > 0){
        let roleList = []
        this.hotelStaffRoleList.forEach( (hotelStaffRoleObj) => {
          if(hotelStaffRoleObj['department_id'] == roomSericeObject['hotel_department']){
            roleList.push(hotelStaffRoleObj)
          }
        });
        roomSericeObject['hotelStaffRoleList'] = roleList;
      }
    }
  };

  updateHotelStaffRoleListInOutlet(outletObj){
    if(outletObj['hotel_department']){
      outletObj['hotel_staff_role'] = null
      if(this.hotelStaffRoleList && this.hotelStaffRoleList.length > 0){
        let roleList = []
        this.hotelStaffRoleList.forEach( (hotelStaffRoleObj) => {
          if(hotelStaffRoleObj['department_id'] == outletObj['hotel_department']){
            roleList.push(hotelStaffRoleObj)
          }
        });
        outletObj['hotelStaffRoleList'] = roleList;
      }
    }
  };

  getCountryList(){
    this.countryList = [];
    this.hotelAdminPanelService.getCountryList({})
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.countryList = response['countries']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getStateList(countryID){
    this.stateList = [];
    this.cityList = [];
    this.stateID = null;
    this.cityID = null;
    if('state_id' in this.currentStaffMemberDetail){
      this.currentStaffMemberDetail['state_id'] = null;
    }
    if('city_id' in this.currentStaffMemberDetail){
      this.currentStaffMemberDetail['city_id'] = null;
    }
    this.hotelAdminPanelService.getStateListByCountry({'country_id': countryID})
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.stateList = response['states']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getCityList(stateID){
    this.cityList = [];
    this.cityID = null;
    if('city_id' in this.currentStaffMemberDetail){
      this.currentStaffMemberDetail['city_id'] = null;
    }
    this.hotelAdminPanelService.getCityListByState({'state_id': stateID})
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.cityList = response['cities']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  clearStaffData(){
    this.firstName =  '';
    this.middleName =  '';
    this.lastName =  '';
    this.employeeCode = '';
    this.isActive = true;
    this.isFrontDeskUser = false;
    this.cellNumber =  '';
    this.cellNumber2 =  '';
    this.email =  '';
    this.password =  '';
    this.confirmPassword =  '';
    this.addressLine1 =  '';
    this.addressLine2 =  '';
    this.managerID =  null;
    this.outletID = null;
    this.departmentID =  null;
    this.hotelStaffRoleList = [];
    this.staffRoleID =  null;
    this.errorMessage =  '';
    this.countryID = null;
    this.stateID = null;
    this.stateList = [];
    this.cityList = [];
    this.cityID = null;
    this.pincode = null;
    this.currentSelectedManager = {};
    this.errorObj = {};
    this.currentSelectedDepartmentObj = {};
    this.currentStaffMemberDetail = {};
    this.isStaffCellNumberExits = false;
    this.isStaffEmployeeCodeExits = false;
    this.isStaffEmailExits = false;
    this.isAvailable = true;
    this.staffCreateModel.nativeElement.click();
    this.staffDetailModel.nativeElement.click();
    this.staffUpdateModel.nativeElement.click();
  };

  setManagerData(event) {
    if (event.target.checked) {
      this.currentSelectedManager = {}
      this.managerID = null;
      this.staffAssignmenManagerID = null;
      if('hotel_manager_id' in this.currentStaffMemberDetail && 
        this.currentStaffMemberDetail['hotel_manager_id']){
          this.currentStaffMemberDetail['hotel_manager_id'] = null
        }
    }
  };

  setStaffActive(event) {
    if (event.target.checked) {
      this.isActive = true;
    }
    else{
      this.isActive = false;
    }
  };

  setStaffFrontDeskUser(event) {
    if (event.target.checked) {
      this.isFrontDeskUser = true;
    }
    else{
      this.isFrontDeskUser = false;
    }
  };

  setStaffIsAvailable(event) {
    if (event.target.checked) {
      this.isAvailable = true;
    }
    else{
      this.isAvailable = false;
    }
  };

  createStaff(){
    let data = {
      'hotel_id': this.currentHotelAdminUserData['hotel_id'],
      'first_name': this.firstName,
      'last_name': this.lastName,
      'employee_code': this.employeeCode,
      'cell_number': this.cellNumber,
      'cell_number2': this.cellNumber2,
      'password': this.password,
      'address_line1': this.addressLine1,
      'address_line2': this.addressLine2,
      'country_id': this.countryID,
      'state_id': this.stateID,
      'city_id': this.cityID,
      'pincode': this.pincode,
      'hotel_department_id': this.departmentID,
      'hotel_staff_role_id': this.staffRoleID,
      'is_active': this.isActive,
      'is_front_desk_user': this.isFrontDeskUser,
      'is_available': this.isAvailable
    }
    if(this.email){
      data['email'] = this.email;
    }
    if(this.middleName){
      data['middle_name'] = this.middleName;
    }
    if(this.managerID){
      data['hotel_manager_id'] = this.managerID;
    }
    if(this.outletID){
      data['outlet_id'] = this.outletID;
    }
    this.hotelAdminPanelService.createHotelStaff(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.clearStaffData();
            this.toastr.success(response['msg'])
            this.getHotelStaffList();
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getCurrentStaffMemberDetail(staffMember){
    this.errorObj = {};
    this.errorMessage = null;
    this.currentStaffMemberDetail = {}
    this.isActive = false
    this.isAvailable = true
    this.isFrontDeskUser = false;
    if(staffMember){
      this.getStateList(staffMember['country_id'])
      this.getCityList(staffMember['state_id'])
      this.getHotelStaffRoleList(staffMember['hotel_department_id'])
      this.currentStaffMemberDetail =  Object.assign({}, staffMember);
      if(this.currentStaffMemberDetail['is_active'] == true){
        this.isActive = true
      }
      if(this.currentStaffMemberDetail['is_front_desk_user'] == true){
        this.isFrontDeskUser = true
      }
      if(this.currentStaffMemberDetail['is_available'] == false){
        this.isAvailable = false
      }
      this.currentSelectedDepartmentObj = this.hotelDepartmentList.find(o => o.id == this.currentStaffMemberDetail['hotel_department_id']);
    }
  };

  closestaffDetailModel(){
    this.staffDetailModel.nativeElement.click();
  };

  updateStaff(){
    let data = {
      'staff_id': this.currentStaffMemberDetail['id'],
      'first_name': this.currentStaffMemberDetail['first_name'],
      'last_name': this.currentStaffMemberDetail['last_name'],
      'cell_number': this.currentStaffMemberDetail['cell_number'],
      'cell_number2': this.currentStaffMemberDetail['cell_number2'],
      'address_line1': this.currentStaffMemberDetail['address_line1'],
      'address_line2': this.currentStaffMemberDetail['address_line2'],
      'country_id': this.currentStaffMemberDetail['country_id'],
      'state_id': this.currentStaffMemberDetail['state_id'],
      'city_id': this.currentStaffMemberDetail['city_id'],
      'pincode': this.currentStaffMemberDetail['pincode'],
      'hotel_department_id': this.currentStaffMemberDetail['hotel_department_id'],
      'hotel_staff_role_id': this.currentStaffMemberDetail['hotel_staff_role_id'],
      'is_active': this.isActive,
      'is_front_desk_user': this.isFrontDeskUser,
      'is_available': this.isAvailable
    }
    if(this.currentStaffMemberDetail['email']){
      data['email'] = this.currentStaffMemberDetail['email'];
    }
    if(this.currentStaffMemberDetail['middle_name']){
      data['middle_name'] = this.currentStaffMemberDetail['middle_name'];
    }
    if(this.currentStaffMemberDetail['hotel_manager_id']){
      data['hotel_manager_id'] = this.currentStaffMemberDetail['hotel_manager_id'];
    }
    if(this.currentStaffMemberDetail['hotel_fnb_outlet_id']){
      data['outlet_id'] = this.currentStaffMemberDetail['hotel_fnb_outlet_id'];
    }
    this.hotelAdminPanelService.updateHotelStaff(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.clearStaffData();
            this.toastr.success(response['msg'])
            this.getHotelStaffList();
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  closeStaffAssignmentDetailModel(){
    this.staffAssignmentRoleID = null;
    this.staffAssignmenDepartmentID = null;
    this.staffAssignmenManagerID = null;
    this.currentSelectedManager = {};
    this.hotelStaffRoleList = [];
    this.staffAssignmentDetailModel.nativeElement.click();
  };

  createStaffAssignment(){
    let data = {
      'hotel_id': this.currentHotelAdminUserData['hotel_id'],
      'staff_id': this.currentStaffMemberDetail['id'],
      'hotel_staff_role_id': this.staffAssignmentRoleID,
      'hotel_department_id': this.staffAssignmenDepartmentID
    }
    if(this.staffAssignmenManagerID){
      data['hotel_manager_id'] = this.staffAssignmenManagerID;
    }
    this.hotelAdminPanelService.createHotelStaffAssignment(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.staffAssignmentCreateModel.nativeElement.click();
            this.staffAssignmentRoleID = null;
            this.staffAssignmenDepartmentID = null;
            this.staffAssignmenManagerID = null;
            this.hotelStaffRoleList = [];
            this.currentStaffMemberDetail = response['data'];
            this.toastr.success(response['msg']);
            this.getHotelStaffList();
          }
          else{
            this.toastr.error(response['msg'], '', {timeOut: 3000,});
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  closeStaffEndAssignmentlModel(){
    this.staffEndAssignmentModel.nativeElement.click();
  };

  staffAssigmentEnd(){
    if(this.currentStaffMemberDetail && 'id' in this.currentStaffMemberDetail){
      let data = {
        'staff_id': this.currentStaffMemberDetail['id']
      }
      this.hotelAdminPanelService.endStaffAssignment(data)
        .subscribe(
          response => {
            if (response['status'] == 1 && response['data']) { 
              this.currentStaffMemberDetail = response['data']
              this.staffEndAssignmentModel.nativeElement.click();
              this.toastr.success(response['msg'])
              this.getHotelStaffList();
            }
            else{
              this.toastr.error(response['msg'])
            }
          },
          error => {
            console.log(error);
          }
        );
      }
  };

  clearDepartmentData(){
    this.errorObj = {};
    this.errorMessage =  '';
    this.departmentName =  '';
    this.departmentShortName =  '';
    this.currentDepartmentObj = {};
    this.deparmentCreateModel.nativeElement.click();
    this.deparmentUpdateModel.nativeElement.click();
  };

  createDepartment(){
    let data = {
      'hotel_id': this.currentHotelAdminUserData['hotel_id'],
      'name': this.departmentName,
      'short_name': this.departmentShortName,
    }
    this.hotelAdminPanelService.createHotelDepartment(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.clearDepartmentData();
            this.toastr.success(response['msg'])
            this.getHotelDepartmentList();
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  setCurrentDepartmentDetail(hotelDepartment){
    this.errorObj = {};
    this.errorMessage = null;
    this.currentDepartmentObj = {}
    if(hotelDepartment){
      this.currentDepartmentObj =  Object.assign({}, hotelDepartment);
    }
  };

  updateDepartment(){
    let data = {
      'department_id': this.currentDepartmentObj['id'],
      'name': this.currentDepartmentObj['name'],
      'short_name': this.currentDepartmentObj['short_name']
    }
    this.hotelAdminPanelService.updateHotelDepartment(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.clearDepartmentData();
            this.toastr.success(response['msg'])
            this.getHotelDepartmentList();
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  removeDepartment(){
    let data = {
      'department_id': this.currentDepartmentObj['id']
    }
    this.hotelAdminPanelService.removeHotelDepartment(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.errorObj = {};
            this.errorMessage = null;
            this.currentDepartmentObj = {}
            this.deparmentDeleteModel.nativeElement.click();
            this.toastr.success(response['msg'])
            this.getHotelDepartmentList();
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  clearStaffRoleData(){
    this.errorObj = {};
    this.errorMessage =  '';
    this.staffRoleName =  '';
    this.staffRoleShortName =  '';
    this.staffRoleDescription =  '';
    this.staffRoleDepartmentID = null
    this.currentStaffRoleObj = {};
    this.staffRoleCreateModel.nativeElement.click();
    this.staffRoleUpdateModel.nativeElement.click();
  };

  createStaffRole(){
    let data = {
      'hotel_id': this.currentHotelAdminUserData['hotel_id'],
      'name': this.staffRoleName,
      'short_name': this.staffRoleShortName,
      'department_id': this.staffRoleDepartmentID
    }
    if(this.staffRoleDescription){
      data['description'] = this.staffRoleDescription
    }
    this.hotelAdminPanelService.createHotelStaffRole(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.clearStaffRoleData();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  setCurrentStaffRoleDetail(staffRole){
    this.errorObj = {};
    this.errorMessage = null;
    this.currentStaffRoleObj = {}
    if(staffRole){
      this.currentStaffRoleObj =  Object.assign({}, staffRole);
    }
  };

  updateStaffRole(){
    let data = {
      'staff_role_id': this.currentStaffRoleObj['id'],
      'name': this.currentStaffRoleObj['name'],
      'short_name': this.currentStaffRoleObj['short_name'],
      'department_id': this.currentStaffRoleObj['department_id']
    }
    if(this.currentStaffRoleObj['description']){
      data['description'] = this.currentStaffRoleObj['description']
    }
    this.hotelAdminPanelService.updateHotelStaffRole(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.clearStaffRoleData();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  removeStaffRole(){
    let data = {
      'staff_role_id': this.currentStaffRoleObj['id']
    }
    this.hotelAdminPanelService.removeHotelStaffRole(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.errorObj = {};
            this.errorMessage = null;
            this.currentStaffRoleObj = {}
            this.staffRoleDeleteModel.nativeElement.click();
            this.toastr.success(response['msg'])
            this.getHotelStaffRoleList('');
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  assignDepartmentAndStaffRoleToRoomService(){
    let room_service_list = []
    this.hotelRoomServiceList.forEach( (hotelRoomServiceObj) => {
      if(hotelRoomServiceObj['hotel_department'] && hotelRoomServiceObj['hotel_staff_role']){
        room_service_list.push({
          'room_service_id': hotelRoomServiceObj['id'],
          'department_id': hotelRoomServiceObj['hotel_department'],
          'staff_role_id': hotelRoomServiceObj['hotel_staff_role']
        })
      }
    });
    if(room_service_list && room_service_list.length > 0){
      let data = {
        'room_service_list': room_service_list
      }
      this.hotelAdminPanelService.assignDepartmentAndStaffRoleToRoomService(data)
        .subscribe(
          response => {
            if (response['status'] == 1) { 
              this.toastr.success(response['msg'])
              this.getHotelRoomServiceList();
            }
            else{
              this.toastr.error(response['msg'])
            }
          },
          error => {
            console.log(error);
          }
        );
    }
    
  };

  sortingStaffListByKey(keyName){
    if(keyName == 'department'){
      this.hotelStaffList.sort(function(a, b){
        if(a.hotel_department_name < b.hotel_department_name) { return -1; }
        if(a.hotel_department_name > b.hotel_department_name) { return 1; }
        return 0;
      })
    }
    else if(keyName == 'role'){
      this.hotelStaffList.sort(function(a, b){
        if(a.hotel_staff_role_name < b.hotel_staff_role_name) { return -1; }
        if(a.hotel_staff_role_name > b.hotel_staff_role_name) { return 1; }
        return 0;
      })
    }
    
  };

  validateStaffCellNumber(cellNumber){
    this.isStaffCellNumberExits = false;
    this.errorObj = {};
    if(cellNumber && String(cellNumber).length >= 10){
      this.hotelAdminPanelService.validateHotelStaffCellNumber({'cell_number': cellNumber})
      .subscribe(
        response => {
          if(response['status'] == 1 && response['data'] && response['data']['is_cell_number_exists'] == false) { 
            this.isStaffCellNumberExits = false;
          }
          else if(response['status'] == 0 && response['data'] && response['data']['is_cell_number_exists'] == true) { 
            this.isStaffCellNumberExits = true;
            this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
            console.log(this.errorObj);
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  };

  validateStaffEmployeeCode(EmployeeCode){
    this.isStaffEmployeeCodeExits = false;
    this.errorObj = {};
    if(EmployeeCode){
      this.hotelAdminPanelService.validateHotelStaffEmployeeCode({
        'employee_code': EmployeeCode,
        'hotel_id': this.currentHotelAdminUserData['hotel_id']
      })
      .subscribe(
        response => {
          if(response['status'] == 1 && response['data'] && response['data']['is_employee_code_exists'] == false) { 
            this.isStaffEmployeeCodeExits = false;
          }
          else if(response['status'] == 0 && response['data'] && response['data']['is_employee_code_exists'] == true) { 
            this.isStaffEmployeeCodeExits = true;
            this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
            console.log(this.errorObj);
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  };

  validateStaffEmail(email){
    this.isStaffEmailExits = false;
    this.errorObj = {};
    const emailRex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(email && emailRex.test(email)){
      this.hotelAdminPanelService.validateHotelStaffEmail({
        'email': email,
      })
      .subscribe(
        response => {
          if(response['status'] == 1 && response['data'] && response['data']['is_email_exists'] == false) { 
            this.isStaffEmailExits = false;
          }
          else if(response['status'] == 0 && response['data'] && response['data']['is_email_exists'] == true) { 
            this.isStaffEmailExits = true;
            this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
            console.log(this.errorObj);
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  };

  assignDepartmentAndStaffRoleToFNBOutlet(){
    let fnb_outlet_list = []
    this.hotelFNBOutletList.forEach( (hotelFnbOutletObj) => {
      if(hotelFnbOutletObj['hotel_department'] && hotelFnbOutletObj['hotel_staff_role']){
        fnb_outlet_list.push({
          'fnb_outlet_id': hotelFnbOutletObj['id'],
          'department_id': hotelFnbOutletObj['hotel_department'],
          'staff_role_id': hotelFnbOutletObj['hotel_staff_role']
        })
      }
    });
    if(fnb_outlet_list && fnb_outlet_list.length > 0){
      let data = {
        'fnb_outlet_list': fnb_outlet_list
      }
      this.hotelAdminPanelService.assignDepartmentAndStaffRoleToFNBOutlet(data)
        .subscribe(
          response => {
            if (response['status'] == 1) { 
              this.toastr.success(response['msg'])
              this.getHotelFNBOutletList();
            }
            else{
              this.toastr.error(response['msg'])
            }
          },
          error => {
            console.log(error);
          }
        );
    }
    
  };

}
