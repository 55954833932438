import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class HotelAdminPanelAuthService {

  constructor() { }

  public isAuthenticated(): boolean {
    if(localStorage.getItem('hotelUserData')){
      return true
    }
    return false
  };

  public isHotelUserLogin(): boolean {
    if(localStorage.getItem('isSignIn') === 'true' && localStorage.getItem('hotelUserData')){
      return true
    }
    return false
  };
}
