import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StaffFrontDeskPanelService } from '../staff-front-desk-panel.service';

@Component({
  selector: 'app-guest-management',
  templateUrl: './guest-management.component.html',
  styleUrls: ['./guest-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GuestManagementComponent implements OnInit {
  @ViewChild('createGuestStayModel', { read: ElementRef, static:false }) public createGuestStayModel: ElementRef;
  @ViewChild('roomSelectionModel', { read: ElementRef, static:false }) public roomSelectionModel: ElementRef;
  @ViewChild('guestStayQRCodeDetailModel', { read: ElementRef, static:false }) public guestStayQRCodeDetailModel: ElementRef;
  @ViewChild('guestInvoiceDetailModel', { read: ElementRef, static:false }) public guestInvoiceDetailModel: ElementRef;
  @ViewChild('guestStayChangeDetailModel', { read: ElementRef, static:false }) public guestStayChangeDetailModel: ElementRef;
  @ViewChild('changeRoomSelectionModel', { read: ElementRef, static:false }) public changeRoomSelectionModel: ElementRef;
  currentHotelStaffUserData = {};
  guestStayList: any[] = [];
  roomList: any[] = [];
  guestFullName: string = '';
  guestCellNumber: string = '';
  guestRoomID: string = '';
  guestRoomNumber: string = '';
  guestRoomWingName: string = '';
  todayDate: Date = new Date();
  tomorrowDate: Date;
  minDateCheckIn: object =  {};
  minDateCheckOut: object =  {};
  guestCheckInDateTimeObj: object = {};
  guestCheckOutDateTimeObj: object = {};
  searchByName: string = '';
  searchByRoomNumber: string = '';
  searchByCellNumber: string = '';
  currentGuestStayQRCodeDetail = {};
  currentQRCodeDetail = '';
  guestMealOrderAndServiceDetail = {}
  guestDetail = {}
  errorObj = {};
  currentSelectionType:string = 'checkout'
  guestChangeCheckoutInDate: object = {};
  changeGuestStayDetailMessage: string = '';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private staffFrontDeskPanelService: StaffFrontDeskPanelService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/frontdesk/design-common.js");
    this.getHotelStaffSessionDetail();
    this.minDateCheckIn = {year: this.todayDate.getFullYear(), month:this.todayDate.getMonth()+1, day:this.todayDate.getDate()};
    this.guestCheckInDateTimeObj = {year: this.todayDate.getFullYear(), month:this.todayDate.getMonth()+1, day:this.todayDate.getDate()};
    this.tomorrowDate = new Date(this.todayDate)
    this.tomorrowDate.setDate(this.tomorrowDate.getDate() + 1)
    this.minDateCheckOut = {year: this.tomorrowDate.getFullYear(), month:this.tomorrowDate.getMonth()+1, day:this.tomorrowDate.getDate()};
    this.guestCheckOutDateTimeObj = {year: this.tomorrowDate.getFullYear(), month:this.tomorrowDate.getMonth()+1, day:this.tomorrowDate.getDate()};
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  getGuestStayList() {
    this.staffFrontDeskPanelService.getGuestStayListByHotel({ 'hotel_id': this.currentHotelStaffUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.guestStayList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelStaffSessionDetail(){
    let response = this.staffFrontDeskPanelService.getHotelStaffUserSessionData()
    if(response['status'] == 1){
      this.currentHotelStaffUserData = response['hotelStaffUserData']
      this.getGuestStayList()
    }
  };

  getRoomList(type) {
    let data = {
      'hotel_id': this.currentHotelStaffUserData['hotel_id']
    }
    if(type == 'create'){
      let checkInTime = new Date(this.guestCheckInDateTimeObj['year'], this.guestCheckInDateTimeObj['month']-1, this.guestCheckInDateTimeObj['day'], 10, 0, 0);
      let checkOutTime = new Date(this.guestCheckOutDateTimeObj['year'], this.guestCheckOutDateTimeObj['month']-1, this.guestCheckOutDateTimeObj['day'], 9, 59, 0);
      data['check_in_timestamp'] = checkInTime.getTime()/1000;
      data['check_out_timestamp'] = checkOutTime.getTime()/1000;
    }
    if(type == 'change-room'){
      let checkInTime = new Date(this.currentGuestStayQRCodeDetail['guest_stay'][0]['check_in']);
      let checkOutTime = new Date(this.currentGuestStayQRCodeDetail['guest_stay'][0]['check_out']);
      data['check_in_timestamp'] = checkInTime.getTime()/1000;
      data['check_out_timestamp'] = checkOutTime.getTime()/1000;
    }
    
    this.roomList = [];
    this.staffFrontDeskPanelService.getRoomListByHotel(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.roomList = response['data']
            this.createGuestStayModel.nativeElement.click();
            this.guestStayChangeDetailModel.nativeElement.click();
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  changeCheckoutDateDetail(event: any){
    if(this.guestCheckInDateTimeObj){
      let checkInTime = new Date(this.guestCheckInDateTimeObj['year'], this.guestCheckInDateTimeObj['month']-1, this.guestCheckInDateTimeObj['day'], 10, 0, 0);
      this.tomorrowDate = new Date(checkInTime)
      this.tomorrowDate.setDate(this.tomorrowDate.getDate() + 1)
      this.minDateCheckOut = {year: this.tomorrowDate.getFullYear(), month:this.tomorrowDate.getMonth()+1, day:this.tomorrowDate.getDate()};
      this.guestCheckOutDateTimeObj = {year: this.tomorrowDate.getFullYear(), month:this.tomorrowDate.getMonth()+1, day:this.tomorrowDate.getDate()};
    }
  };

  clearGuestStayDetailData(){
    this.roomList = []
    this.guestFullName = '';
    this.guestCellNumber = '';
    this.guestRoomID = '';
    this.guestRoomNumber = '';
    this.guestRoomWingName = '';
    this.currentSelectionType = 'checkout'
    this.tomorrowDate = new Date(this.todayDate)
    this.tomorrowDate.setDate(this.tomorrowDate.getDate() + 1)
    this.guestCheckInDateTimeObj = {year: this.todayDate.getFullYear(), month:this.todayDate.getMonth()+1, day:this.todayDate.getDate()};
    this.guestCheckOutDateTimeObj = {year: this.tomorrowDate.getFullYear(), month:this.tomorrowDate.getMonth()+1, day:this.tomorrowDate.getDate()};
    this.errorObj = {};
    this.createGuestStayModel.nativeElement.click();
  };

  clearChangeGuestStayDetailData(){
    this.roomList = []
    this.guestRoomID = '';
    this.guestRoomNumber = '';
    this.guestRoomWingName = '';
    this.guestChangeCheckoutInDate = {};
    this.errorObj = {};
    this.currentGuestStayQRCodeDetail = {};
    this.changeGuestStayDetailMessage = '';
    this.currentSelectionType = 'checkout'
    this.guestStayChangeDetailModel.nativeElement.click();
  };

  roomSelectionToggle(roomObj){
    if(roomObj && roomObj.is_occupied == false && roomObj.is_available == true){
      this.guestRoomID = roomObj.room_id;
      this.guestRoomNumber = roomObj.wing_name + '-' + String(roomObj.room_number);
      this.guestRoomWingName = roomObj.wing_name;
      this.createGuestStayModel.nativeElement.click();
      this.guestStayChangeDetailModel.nativeElement.click();
    }
  };

  cancleRoomSelectionModel(){
    this.roomList = []
    this.guestRoomID = '';
    this.guestRoomNumber = '';
    this.guestRoomWingName = '';
    this.roomSelectionModel.nativeElement.click();
    this.currentSelectionType = 'checkout'
    this.changeRoomSelectionModel.nativeElement.click();
  }

  roomSelection(){
    this.roomSelectionModel.nativeElement.click();
  };

  changeRoomSelection(){
    this.guestStayChangeDetailModel.nativeElement.click();
  };

  createGuestStay(){
    let checkInTime = new Date(this.guestCheckInDateTimeObj['year'], this.guestCheckInDateTimeObj['month']-1, this.guestCheckInDateTimeObj['day'], 10, 0, 0);
    let checkOutTime = new Date(this.guestCheckOutDateTimeObj['year'], this.guestCheckOutDateTimeObj['month']-1, this.guestCheckOutDateTimeObj['day'], 9, 59, 0);
    let data = {
      'hotel_id': this.currentHotelStaffUserData['hotel_id'],
      'full_name': this.guestFullName,
      'cell_number': this.guestCellNumber,
      'cell_number2': this.guestCellNumber,
      'room_id': this.guestRoomID,
      'check_in_timestamp': checkInTime.getTime()/1000,
      'check_out_timestamp': checkOutTime.getTime()/1000,
      'hotel_staff_id': this.currentHotelStaffUserData['id'],
      'created_by_id': this.currentHotelStaffUserData['id'],
      'updated_by_id': this.currentHotelStaffUserData['id']
    }
    this.staffFrontDeskPanelService.createGuestStay(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.getGuestStayList()
            this.toastr.info('Guest stay details added successfully')
            this.clearGuestStayDetailData()
            this.setCurrentGuestStayQRCodeDetail(response['data'])
          }
          else{
            if(response['msg'] && response['status'] == 0){
              this.errorObj = this.staffFrontDeskPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  searchGuestStay(currentSearchField){
    let searchData = {
      'hotel_id': this.currentHotelStaffUserData['hotel_id']
    }
    if(currentSearchField == 'name'){
      searchData['name'] = this.searchByName
    }
    if(currentSearchField == 'cellNumber'){
      searchData['cell_number'] = this.searchByCellNumber
    }
    if(currentSearchField == 'roomNumber'){
      searchData['room_number'] = this.searchByRoomNumber
    }
    this.staffFrontDeskPanelService.searchGuestStayList(searchData)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.guestStayList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  setCurrentGuestStayQRCodeDetail(currentGuestStayDetail){
    if(currentGuestStayDetail){
      this.currentGuestStayQRCodeDetail = currentGuestStayDetail;
      this.tomorrowDate = new Date(this.currentGuestStayQRCodeDetail['guest_stay'][0]['check_in'])
      this.tomorrowDate.setDate(this.tomorrowDate.getDate() + 1)
      this.minDateCheckOut = {year: this.tomorrowDate.getFullYear(), month:this.tomorrowDate.getMonth()+1, day:this.tomorrowDate.getDate()};
      this.currentSelectionType = 'checkout'
      // let inputVal = 100000;
      // let hexString = inputVal.toString(16);
      // let yourNumber = parseInt(hexString, 16); 
      this.currentQRCodeDetail = String(this.currentGuestStayQRCodeDetail['guest_stay'][0]['id'].toString(16))
    }
    else{
      this.currentGuestStayQRCodeDetail = {}
      this.currentQRCodeDetail = '';
      this.currentSelectionType = 'checkout'
    }
  }

  getGuestFoodOrderAndRoomServiceDetails(guestData){
    this.guestDetail = guestData;
    let checkInTimestamp = new Date(guestData['guest_stay'][0]['check_in']);
    let checkOutTimestamp = new Date(guestData['guest_stay'][0]['check_out']);
    let searchData = {
      'room_id': guestData['guest_stay'][0]['room_id'],
      'check_in_timestamp': checkInTimestamp.getTime()/1000,
      'check_out_timestamp': checkOutTimestamp.getTime()/1000
    }
    this.staffFrontDeskPanelService.getGuestOrderAndServiceDetail(searchData)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.guestMealOrderAndServiceDetail = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  updateGuestStayDetail(changeDataType){
    let data = {
      'stay_id': this.currentGuestStayQRCodeDetail['guest_stay'][0]['id'],
      'staff_id': this.currentHotelStaffUserData['id'],
    }
    if(changeDataType == 'change-room'){
      data['room_id'] = this.guestRoomID
    }
    if(changeDataType == 'end-stay'){
      data['check_out_timestamp'] = Math.round(+new Date()/1000);
    }
    if(changeDataType == 'change-checkout-date'){
      let checkInTime = new Date(this.guestChangeCheckoutInDate['year'], this.guestChangeCheckoutInDate['month']-1, this.guestChangeCheckoutInDate['day'], 10, 0, 0);
      data['check_out_timestamp'] = checkInTime.getTime()/1000;
    }
    this.staffFrontDeskPanelService.updateGuestStayDetail(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            // this.currentGuestStayQRCodeDetail['guest_stay'][0]['room_number'] = this.guestRoomNumber
            // this.currentGuestStayQRCodeDetail['guest_stay'][0]['room_id'] = this.guestRoomID
            // this.currentGuestStayQRCodeDetail['guest_stay'][0]['wing_name'] = this.guestRoomWingName
            // this.clearChangeGuestStayDetailData();
            if(changeDataType == 'change-room'){
              this.changeGuestStayDetailMessage = 'Guest stay room number successfully changed'
              this.changeRoomSelectionModel.nativeElement.click();
            }
            if(changeDataType == 'end-stay'){
              this.changeGuestStayDetailMessage = 'Check-Out Confirmed'
              this.guestStayChangeDetailModel.nativeElement.click();
            }
            if(changeDataType == 'change-checkout-date'){
              this.changeGuestStayDetailMessage = 'Check-out date successfully changed'
              this.guestStayChangeDetailModel.nativeElement.click();
            }
            this.getGuestStayList()
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  isGuestCheckout(checkoutDateTime){
    if(checkoutDateTime){
      let checkOutTimestamp = new Date(checkoutDateTime).getTime()/1000;
      var currentTimestamp = Math.round(+new Date()/1000);
      if(checkOutTimestamp > currentTimestamp){
        return true
      }
      return false

    }
  }

  sendLoginCodeGuestUser(){
    this.staffFrontDeskPanelService.sendLoginCodeToGuest({'cell_number': this.currentGuestStayQRCodeDetail['cell_number']})
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.toastr.success(response['msg'])
          }
          else{
            if(response['msg'] && response['status'] == 0){
              this.errorObj = this.staffFrontDeskPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

}
