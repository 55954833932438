import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { GuestPanelAuthService } from '../guest-panel-auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuestQrCodeLoginGuardService implements  CanActivate{

  constructor(public auth: GuestPanelAuthService, public router: Router) {}

  canActivate() {
    if(this.auth.isGuestUserLogin()) {
      this.router.navigate(['/guest/appliances'])
      return false;
    }
    else {
      return true;
    }
  };
}