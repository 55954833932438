import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuestPanelService {

  constructor( private httpClient: HttpClient) { }

  // store guest session data
  storeHotelGuestUserSessionData(data, isSignIn){
    data['scope'] = 'hotelGuestUser'
    localStorage.setItem('hotelGuestUserData', JSON.stringify(data))
    if(isSignIn){
      localStorage.setItem('isSignIn', JSON.stringify(isSignIn))
    }
    return({
      'status': 1,
      'msg': 'hotel guest user session details successfully set.'
    });
  };

  // get hotel guest user session data
  getHotelGuestUserSessionData(){
    let hotelGuestUserData = JSON.parse(localStorage.getItem('hotelGuestUserData'));
    let isSignIn = JSON.parse(localStorage.getItem('isSignIn'));
    return({
      'status': 1,
      'hotelGuestUserData': hotelGuestUserData,
      'isSignIn': isSignIn,
      'msg': 'hotel guest user session details successfully get.'
    });
  };

  // clear hotel guest user session data
  clearHotelGuestUserSessionData(){
    localStorage.removeItem('isSignIn')
    localStorage.removeItem('hotelGuestUserData')
    return({
      'status': 1,
      'msg': 'hotel staff user session details successfully clear.'
    });
  };

  getErrorResponse(message){
    if(message){
      const result = message.split(':');
      if(result && result.length == 2){
        let objKey = result[0];
        let objValue = result[1];
        let errorObj = {}
        errorObj[objKey] = objValue
        return errorObj
      }
      return {}
    }
    return {}
  };

  getHotelGuestStayDetail(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/guest/get-guest-details/',
        data
    );
  };

  validateCurrentGuestStayDetail(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/guest/validate-guest-stay-details/',
        data
    );
  };

  getHotelRoomServiceList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/guest/get-room-service-list-by-hotel/',
        data
    );
  };

  placeRoomServiceRequest(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/guest/create-hotel-current-room-service-request/',
        data
    );
  };

  getFoodMenuCategoryList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/guest/get-food-menu-item-category-list/',
        data
    );
  };

  getFoodMenuItemListByCategory(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/guest/get-food-item-list-by-food-category/',
        data
    );
  };

  placeFoodOrderRequest(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/guest/place-hotel-food-ird-order/',
        data
    );
  };

  getAppliancesList(data, headers){
    return this.httpClient.post(
      environment.apiBaseURL + '/api/get-unit-report/',
      data,
      {'headers': headers}
    );
  };

  getFirebaseCredentialFromServer(){
    return this.httpClient.post(
      environment.apiBaseURL + '/api/get-login-details/',
      {'access_token': '67g31a29c3x007zd22d96228eaeb23a60df96e76'}
    );
  };

  getHotelFNBOutletList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/guest/get-fnb-outlet-list-by-hotel/',
        data
    );
  };

  isFoodBeverageServiceEnable(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/guest/is-food-beverage-service-enable/',
        data
    );
  };
  
}
