import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { ToastrService } from 'ngx-toastr';
import { GuestPanelService } from '../guest-panel.service';


@Component({
  selector: 'app-guest-qr-code-login',
  templateUrl: './guest-qr-code-login.component.html',
  styleUrls: ['./guest-qr-code-login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GuestQrCodeLoginComponent implements OnInit {
  stayID: any;
  tempstayID: any;
  @ViewChild('scanner', { static: false })scanner: ZXingScannerComponent;

  constructor(
    private guestPanelService: GuestPanelService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/guest/design-common.js");
  }

  public loadJsFile(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('body')[0].appendChild(node);  
  } 


  getGuestStayDetail(){
    let data = {
      'guest_stay_id': this.stayID,
    }
    this.guestPanelService.getHotelGuestStayDetail(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            let result = this.guestPanelService.storeHotelGuestUserSessionData(response['data'], true)
            if(result['status'] == 1){
              setTimeout(() => {
                this.scanner.reset();
                this.router.navigate(['/guest/appliances'])
              },3000)
              
            }
          }
          else if(response['status'] == 0){
              this.toastr.error(response['msg']);
              this.scanner.enable = true;
          }
        },
        error => {
          console.log(error);
        }
    );
  }

  onCodeResult(resultString: string) {
    if(resultString){
      this.stayID  = parseInt(resultString, 16);
      if(this.stayID && this.stayID.toString().length == 6){
        this.getGuestStayDetail();
      }
      else{
        setTimeout(() => {
          this.toastr.error('your qr code not valid.please scan valid qr code');
        },5000)
      }
    }
  }

  loginWithStayID(tempStayID){
    if(tempStayID){
      this.stayID  = parseInt(tempStayID, 16);
      console.log(this.stayID)
      if(this.stayID && this.stayID.toString().length == 6){
        this.getGuestStayDetail();
      }
      else{
        setTimeout(() => {
          this.toastr.error('your login code not valid.please enter valid login code');
        },5000)
      }
    }
  };

}
