import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import { HotelAdminPanelService } from '../../hotel-admin-panel.service';

@Component({
  selector: 'app-hotel-admin-left-navigation',
  templateUrl: './hotel-admin-left-navigation.component.html',
  styleUrls: ['./hotel-admin-left-navigation.component.css']
})
export class HotelAdminLeftNavigationComponent implements OnInit {

  hotelUserData = {};
  currentActiveRoute: string

  constructor(
    private router: Router,
    private hotelAdminPanelService: HotelAdminPanelService
  ) { }

  ngOnInit() {
    this.currentActiveRoute = this.router.url;
    this.getHotelSessionDetail();
  }

  getHotelSessionDetail(){
    let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
    if(response['status'] == 1){
      this.hotelUserData = response['hotelUserData']
    }
  };

}
