import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { HotelAdminPanelAuthService } from '../hotel-admin-panel-auth.service';
import { HotelAdminPanelService } from '../hotel-admin-panel.service';


@Injectable({
  providedIn: 'root'
})
export class HotelLoginGuardService implements CanActivate {

  constructor(
    public auth: HotelAdminPanelAuthService, 
    public hotelAdminPanelService: HotelAdminPanelService, 
    public router: Router) {}

  canActivate() {
    if(this.auth.isHotelUserLogin()) {
      let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
      if(response['status'] == 1){
        let hotelUserData = response['hotelUserData']
        if(hotelUserData && 'is_super_admin' in hotelUserData && hotelUserData['is_super_admin'] == false &&
          'department_name' in hotelUserData && (hotelUserData['department_name'] == 'Engineering' ||
           hotelUserData['department_name'] == 'House Keeping')){
            this.router.navigate(['/hotel-admin/room-service-management'])
            return false;
        }
        if(hotelUserData && 'is_super_admin' in hotelUserData && hotelUserData['is_super_admin'] == false &&
          'department_name' in hotelUserData && hotelUserData['department_name'] == 'Food & Beverages'){
            this.router.navigate(['/hotel-admin/food-management'])
            return false;
        }
        else{
          this.router.navigate(['/hotel-admin/room-management'])
          return false;
        }
      }
    }
    else {
      return true;
    }
  };
}
