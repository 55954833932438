import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  ViewEncapsulation
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { Router } from "@angular/router"
import { environment } from 'src/environments/environment';
import { StaffFrontDeskPanelService } from '../staff-front-desk-panel.service';

@Component({
  selector: 'app-guest-room-service-request',
  templateUrl: './guest-room-service-request.component.html',
  styleUrls: ['./guest-room-service-request.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GuestRoomServiceRequestComponent implements OnInit {
  @ViewChild('roomServiceRequestHistoryModel', { read: ElementRef, static:false }) public roomServiceRequestHistoryModel: ElementRef;
  webSocket: WebSocketSubject<any>;
  messages: Observable<any>;
  subscription: Subscription;
  currentHotelStaffUserData = {};
  currentHotelRoomServiceRequestList: any[] = [];
  roomServiceRequestHistoryList: any[] = [];
  filterByDay: Number = null;
  constructor(
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private staffFrontDeskPanelService: StaffFrontDeskPanelService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/frontdesk/design-common.js");
    this.webSocket = webSocket(environment.webSoketBaseURL + '/hotel-chain/');
    this.webSocket.asObservable().subscribe(dataFromServer => {
      if(dataFromServer['event'] == 'receive_new_room_service_request' && 
        dataFromServer['data'] && 
        dataFromServer['data']['hotel_id'] == this.currentHotelStaffUserData['hotel_id']){
        this.getCurrentRoomServiceRequestRequestList();
        this.toastr.info('New Room Service Request Received', 
            'Room No: ' + dataFromServer['data']['wing_name'] + '-' + dataFromServer['data']['room_number']
          )
      }

      if(dataFromServer['event'] == 'update_room_service_request_status' && 
        dataFromServer['data'] && 
        dataFromServer['data']['hotel_id'] == this.currentHotelStaffUserData['hotel_id']){
        this.getCurrentRoomServiceRequestRequestList();
      }

      if(dataFromServer['event'] == 'remove_room_service_request'){
        this.getCurrentRoomServiceRequestRequestList();
      }
    });
    this.getHotelStaffSessionDetail()
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  getHotelStaffSessionDetail(){
    let response = this.staffFrontDeskPanelService.getHotelStaffUserSessionData()
    if(response['status'] == 1){
      this.currentHotelStaffUserData = response['hotelStaffUserData']
      this.getCurrentRoomServiceRequestRequestList();
      this.getRoomServiceRequestHistoryList();
    }
  };

  getCurrentRoomServiceRequestRequestList(){
    this.currentHotelRoomServiceRequestList = []
    this.httpClient.post(
      environment.hotelChainBaseURL + '/api/staff/get-current-room-service-request-by-hotel/',
      {'hotel_id': this.currentHotelStaffUserData['hotel_id']}
    ).subscribe(response => {
        if(response['status'] == 1){
          this.currentHotelRoomServiceRequestList = response['data'];
        }
    }, error=>{
      console.log(error)
    })
  };

  getRoomServiceRequestHistoryList(){
    this.filterByDay = null;
    this.roomServiceRequestHistoryList = []
    this.httpClient.post(
      environment.hotelChainBaseURL + '/api/staff/get-room-service-request-history-by-hotel/',
      {'hotel_id': this.currentHotelStaffUserData['hotel_id']}
    ).subscribe(response => {
        if(response['status'] == 1){
          this.roomServiceRequestHistoryList = response['data'];
        }
    }, error=>{
      console.log(error)
    })
  };

  getRoomServiceRequestHistoryListByDate(days){
    if(days){
      this.roomServiceRequestHistoryList = []
      this.httpClient.post(
        environment.hotelChainBaseURL + '/api/staff/get-room-service-request-history-by-hotel/',
        {'hotel_id': this.currentHotelStaffUserData['hotel_id'], 'filter_by_day': Number(days)}
      ).subscribe(response => {
          if(response['status'] == 1){
            this.roomServiceRequestHistoryList = response['data'];
          }
      }, error=>{
        console.log(error)
      })
    };
  };

  updateCurrentRoomSeriveRequestStatus(currentRoomServiceStatus, currentRoomServiceRequestData){
    let data = {
      'staff_id': this.currentHotelStaffUserData['id'],
      'current_room_service_request_id': currentRoomServiceRequestData.id,
      'service_request_status_type_id': currentRoomServiceStatus,
      'current_timestamp': Math.floor(Date.now()/1000)
    }
    this.httpClient.post(
      environment.hotelChainBaseURL + '/api/staff/update-hotel-current-room-service-status/',
      data
    ).subscribe(response => {
        if(response['status'] == 1){
          this.toastr.info('Room Service request status successfully updated', 
          'Room No: ' + currentRoomServiceRequestData['wing_name'] + '-' +currentRoomServiceRequestData['room_number'])
        }
    }, error=>{
      console.log(error)
    })
  };
  

}
