import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Router} from "@angular/router"
import { Subscription } from 'rxjs';
import { HotelAdminPanelService } from '../hotel-admin-panel.service';

@Component({
  selector: 'app-hotel-admin-login',
  templateUrl: './hotel-admin-login.component.html',
  styleUrls: ['./hotel-admin-login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HotelAdminLoginComponent implements OnInit {
  subscription: Subscription;
  emailOrMobileNumber;
  emailOrPasswordErrorMessage: string = ''
  isSignIn: boolean = true;
  password: string;
  loginErrorMessage: string;
  isUserLogin: boolean = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpClient: HttpClient,
    private router: Router,
    private hotelAdminPanelService: HotelAdminPanelService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/admin/design-common.js");
  }

  public loadJsFile(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('body')[0].appendChild(node);  
  } 
  
  validateEmailOrMobileNumber(){
    this.emailOrPasswordErrorMessage = '';
    if(isNaN(this.emailOrMobileNumber) == true) {
      let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if(reg.test(this.emailOrMobileNumber) == false) {
          this.emailOrPasswordErrorMessage = 'Please enter a valid email'
      }
      else{
        this.emailOrPasswordErrorMessage = '';
      }
    }
    else if (isNaN(this.emailOrMobileNumber) == false) {
      let reg_mobile = /^(\+\d{1,3}[- ]?)?\d{10}$/;
      if (reg_mobile.test(this.emailOrMobileNumber) == false) {
        this.emailOrPasswordErrorMessage = 'Please enter a valid mobile no.'
      }
      else{
        this.emailOrPasswordErrorMessage = '';
      }
    }
  }

  login(){
    this.loginErrorMessage = null;
    this.isUserLogin = true;
    this.httpClient.post(
      environment.apiBaseURL + '/api/hotel/login/',
      {'email_or_mobile': this.emailOrMobileNumber, 'password': this.password}
    ).subscribe(response => {
        if(response['status'] === 1 && response['data']){
          let data = response['data']
          let result = this.hotelAdminPanelService.storeHotelAdminfUserSessionData(data, this.isSignIn)
          if(result['status'] == 1){
            if(data && 'is_super_admin' in data && data['is_super_admin'] == false &&
              'department_name' in data && (data['department_name'] == 'Engineering' ||
              data['department_name'] == 'House Keeping')){
                this.router.navigate(['/hotel-admin/room-service-management'])
                return false;
            }
            else if(data && 'is_super_admin' in data && data['is_super_admin'] == false &&
              'department_name' in data && data['department_name'] == 'Food & Beverages'){
                this.router.navigate(['/hotel-admin/food-management'])
                return false;
            }
            else{
              this.router.navigate(['/hotel-admin/room-management'])
              return false;
            }
          }
          else{
            this.isUserLogin = false;
          }
        }
        else{
          this.isUserLogin = false;
          this.loginErrorMessage = 'Please enter a correct mobile no. and password.';
        }
    })
  }


  isSignInToggle(event) {
    if (event.target.checked) {
      this.isSignIn = true;
    }
    else{
      this.isSignIn = false;
    }
  }

}
