import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  Inject,
  ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router"
import { FileUploader } from 'ng2-file-upload';
import { HotelAdminPanelService } from '../hotel-admin-panel.service';

@Component({
  selector: 'app-food-management',
  templateUrl: './food-management.component.html',
  styleUrls: ['./food-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FoodManagementComponent implements OnInit {
  @ViewChild('foodItemCreateModel', { read: ElementRef, static: false }) public foodItemCreateModel: ElementRef;
  @ViewChild('foodItemUpdateModel', { read: ElementRef, static: false }) public foodItemUpdateModel: ElementRef;
  @ViewChild('foodItemRemoveModel', { read: ElementRef, static: false }) public foodItemRemoveModel: ElementRef;
  @ViewChild('fnbOutletCreateModel', { read: ElementRef, static: false }) public fnbOutletCreateModel: ElementRef;
  @ViewChild('fnbOutletUpdateModel', { read: ElementRef, static: false }) public fnbOutletUpdateModel: ElementRef;
  @ViewChild('fnbOutletRemoveModel', { read: ElementRef, static: false }) public fnbOutletRemoveModel: ElementRef;
  @ViewChild('fnbOutletTableCreateModel', { read: ElementRef, static: false }) public fnbOutletTableCreateModel: ElementRef;
  @ViewChild('fnbOutletTableUpdateModel', { read: ElementRef, static: false }) public fnbOutletTableUpdateModel: ElementRef;
  @ViewChild('fnbOutletTableRemoveModel', { read: ElementRef, static: false }) public fnbOutletTableRemoveModel: ElementRef;
  @ViewChild('fileUploaderEle', { read: ElementRef, static: false }) public fileUploaderEle: ElementRef;
  public uploader: FileUploader = new FileUploader({});
  currentHotelAdminUserData = {};
  errorObj = {}
  errorMessage: string = '';
  hotelfoodItemList = [];
  foodMenuItemTimeList = [];
  foodMenuItemCategoryList = [];
  hotelFNBOutletList = [];
  hotelFNBOutletTableList = [];
  foodItemName: string = '';
  foodMenuItemCategoryID: number = null;
  foodItemType: string = null;
  FoodMenuItemOutletID: number = null;
  foodMenuItemTimeID: number = null;
  foodMenuItemTime2ID: number = null;
  foodItemDescription: string = '';
  foodItemIngredients: string = '';
  foodItemPrice: number = null;
  foodItemSpecialPrice: number = null;
  foodItemTaxRate: number = 10;
  foodItemPreparationTime: number = null;
  foodItemAvailable: boolean = true;
  isFoodItemMediaFileUpload: boolean = false;
  foodItemMediaFile: any[];
  foodItemMediaFileIndex: number = 1
  currentFoodItemObj = {};
  currentFNBOutletObj: object = {};
  outletName: string = '';
  outletTax: Number = null;
  outletDeliveryModeList = ['Dine-In', 'Delivery', 'Both'];
  outletDeliveryMode: string = null;
  outletNumberOfTable: Number = null;
  outletLocationDescription: string = '';
  outletDeliveryLocation: string = '';
  outletDescription: string = '';
  outletActive: boolean = true;
  outletBanner: any;
  isOutletBannerFileUpload: boolean = false;
  currentSelectedOutletID: number = null;
  currentSelectedOutlet: object = {};
  currentSelectedOutletTable: object = {};
  isOutletButtonInProcess: boolean = false
  foodTypeList = ['Veg', 'Veg-Contains Egg', 'Non-Veg', 'Vegan'];
  outletTableStatusList = [
    {
      'name': 'VACANT'
    },
    {
      'name': 'RESERVED'
    },
    {
      'name': 'OCCUPIED'
    }
  ];
  outletTableNumber: Number = null;
  outletTablePersonCapacity: number = null;
  outletTableStatus: string = null;
  constructor(
    private toastr: ToastrService,
    private hotelAdminPanelService: HotelAdminPanelService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/admin/design-common.js");
    this.getHotelAdminUserSessionDetail();
  }

  public loadJsFile(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('body')[0].appendChild(node);  
  }  

  getHotelAdminUserSessionDetail() {
    let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
    if (response['status'] == 1) {
      this.currentHotelAdminUserData = response['hotelUserData']
      this.getHotelFoodItemList();
      this.getHotelFNBOutletList();
      this.getHotelFoodMenuItemTimeList();
      this.getHotelFoodMenuItemCategoryList();
    }
  };

  getHotelFoodItemList() {
    this.hotelfoodItemList = [];
    let data =  {
      'hotel_id': this.currentHotelAdminUserData['hotel_id']
    }
    if(this.currentSelectedOutletID && this.currentSelectedOutletID != null){
      data['outlet_id'] = this.currentSelectedOutletID
      this.currentSelectedOutlet = this.hotelFNBOutletList.find(o => o.id == this.currentSelectedOutletID);
    }
    this.hotelAdminPanelService.getHotelFoodItemList(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.hotelfoodItemList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelFoodMenuItemTimeList() {
    this.hotelAdminPanelService.getFoodMenuItemTimeList({})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.foodMenuItemTimeList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelFoodMenuItemCategoryList() {
    this.hotelAdminPanelService.getFoodMenuCategoryList({})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.foodMenuItemCategoryList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelFNBOutletList() {
    this.hotelAdminPanelService.getHotelFNBOutletList({'hotel_id': this.currentHotelAdminUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.hotelFNBOutletList = response['data']
            if(this.hotelFNBOutletList && this.hotelFNBOutletList.length > 0){
              this.currentSelectedOutletID = this.hotelFNBOutletList[0]['id']
              this.currentSelectedOutlet = this.hotelFNBOutletList[0]
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelFNBOutletTableList() {
    let data = {}
    if(this.currentSelectedOutletID && this.currentSelectedOutletID != null){
      data['outlet_id'] = this.currentSelectedOutletID
    }
    this.hotelAdminPanelService.getHotelFNBOutletTableList(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.hotelFNBOutletTableList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  clearFoodItemData() {
    this.errorMessage = '';
    this.errorObj = {};
    this.foodItemName = '';
    this.foodMenuItemCategoryID = null;
    this.foodItemType = null;
    this.foodMenuItemTimeID = null;
    this.foodMenuItemTime2ID = null;
    this.foodItemDescription = '';
    this.foodItemIngredients = null;
    this.foodItemPrice = null;
    this.foodItemSpecialPrice = null;
    this.foodItemTaxRate = 10;
    this.foodItemPreparationTime = null;
    this.foodItemAvailable = true;
    this.isFoodItemMediaFileUpload = false;
    this.foodItemMediaFile = [];
    this.currentFoodItemObj = {};
    this.foodItemMediaFileIndex = 1;
    this.FoodMenuItemOutletID = null;
    this.foodItemCreateModel.nativeElement.click();
  };

  clearFNBOutletData(){
    this.fnbOutletCreateModel.nativeElement.click();
    this.fnbOutletUpdateModel.nativeElement.click();
    this.errorMessage = '';
    this.errorObj = {};
    this.outletName = '';
    this.outletDeliveryMode = null;
    this.outletNumberOfTable = null;
    this.outletTax = null;
    this.outletDescription = '';
    this.outletDeliveryLocation = '';
    this.outletLocationDescription = '';
    this.outletBanner = null;
    this.fileUploaderEle.nativeElement.value = null;
    this.isOutletBannerFileUpload = false;
    this.currentFNBOutletObj = {};
    this.outletActive = true;
    this.isOutletButtonInProcess = false;
  }

  clearFNBOutletTableData(){
    this.errorMessage = '';
    this.errorObj = {};
    this.outletTableNumber = null;
    this.outletTablePersonCapacity = null;
    this.outletTableStatus = null;
    this.fnbOutletTableCreateModel.nativeElement.click();
  }

  // upload profile picture on firebase
  uploadFoodItemMediaFile(files) {
    this.isFoodItemMediaFileUpload = false;
    let tempFoodItemMediaFile = Array.from(files);
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = (event) => {
        this.foodItemMediaFile.push({
          'localURL': reader.result,
          'id': '',
          'url': '',
          'status': 'uploading'
        })
      }
    }

    let uploadFoodItemMediaMediaOnServer = (mediaFile) => {
      let foodItemMediaFormData: FormData = new FormData();
      foodItemMediaFormData.append('file', mediaFile);
      this.hotelAdminPanelService.uploadMediaFile(foodItemMediaFormData)
        .subscribe(response => {
          console.log(response)
          if (response['status'] == 1 && response['data']) {
            let currentIndex = this.foodItemMediaFileIndex - 1
            this.foodItemMediaFile[currentIndex]['url'] = response['data']['url']
            this.foodItemMediaFile[currentIndex]['localURL'] = response['data']['url']
            this.foodItemMediaFile[currentIndex]['id'] = response['data']['id']
            this.foodItemMediaFile[currentIndex]['status'] = 'successfully'
            let index = tempFoodItemMediaFile.indexOf(mediaFile);
            if (index > -1) {
              tempFoodItemMediaFile.splice(index, 1);
            }
            if (tempFoodItemMediaFile && tempFoodItemMediaFile.length > 0) {
              this.foodItemMediaFileIndex = this.foodItemMediaFileIndex + 1
              uploadFoodItemMediaMediaOnServer(tempFoodItemMediaFile[0]);
            }
            else {
              this.isFoodItemMediaFileUpload = true;
              console.log(this.foodItemMediaFile)
            }
          }
        }, error => {
          console.log(error)
        })
    };
    if (tempFoodItemMediaFile && tempFoodItemMediaFile.length > 0) {
      this.foodItemMediaFileIndex = this.foodItemMediaFile.length + 1
      uploadFoodItemMediaMediaOnServer(tempFoodItemMediaFile[0])
    }
  }

  removeFoodItemMediaMediaFile(mediaFile){
    let index = this.foodItemMediaFile.indexOf(mediaFile);
    if (index > -1) {
      this.foodItemMediaFile.splice(index, 1);
    }
  };

  isFoodItemAvailableStatus(event) {
    if (event.target.checked) {
      this.foodItemAvailable = true;
    }
    else{
      this.foodItemAvailable = false;
    }
  }

  addMealItem() {
    let foodMediaID = [];
    if(this.foodItemMediaFile && this.foodItemMediaFile.length > 0){
      for (let i = 0; i < this.foodItemMediaFile.length; i++) {
        foodMediaID.push(this.foodItemMediaFile[i]['id'])
      }
    }
    let mealItemData = {
      'hotel_id': this.currentHotelAdminUserData['hotel_id'],
      'created_by_id': this.currentHotelAdminUserData['id'],
      'name': this.foodItemName,
      'hotel_menu_item_time_id': this.foodMenuItemTimeID,
      'hotel_menu_item_category_id': this.foodMenuItemCategoryID,
      'type': this.foodItemType,
      'hotel_menu_item_outlet_id': this.FoodMenuItemOutletID,
      'price': this.foodItemPrice,
      'tax_rate': this.foodItemTaxRate,
      'preparation_time': this.foodItemPreparationTime,
      'is_available': this.foodItemAvailable,
      'food_item_media': [],
    }
    if(this.foodItemType){
      if(this.foodItemType == 'Veg'){
        mealItemData['type'] = 'Vegetarian'
      }
      else if(this.foodItemType == 'Non-Veg'){
        mealItemData['type'] = 'Non-Vegetarian'
      }
      else if(this.foodItemType == 'Veg-Contains Egg'){
        mealItemData['type'] = 'Vegetarian-Contains Egg'
      }
      else{
        mealItemData['type'] = 'Vegan'
      }
    }
    else{
      mealItemData['type'] = ''
    }
    if(this.foodMenuItemTime2ID){
      mealItemData['hotel_menu_item_time2_id'] = this.foodMenuItemTime2ID;
    }
    if(this.foodItemIngredients){
      mealItemData['ingredients'] = this.foodItemIngredients;
    }
    if(this.foodItemSpecialPrice){
      mealItemData['special_price'] = this.foodItemSpecialPrice;
    }
    if (this.foodItemDescription) {
      mealItemData['description'] = this.foodItemDescription;
    }
    if(foodMediaID && foodMediaID.length > 0){
      mealItemData['food_item_media'] = foodMediaID;
    }
    this.hotelAdminPanelService.createHotelFoodItem(mealItemData)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.getHotelFoodItemList()
            this.toastr.success(response['msg'], response['data']['name'])
            this.clearFoodItemData()
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  showFoodItemUpdateData(data) {
    this.currentFoodItemObj = {};
    this.foodItemMediaFile = [];
    this.errorObj = {};
    this.currentFoodItemObj = Object.assign({}, data);
    if(this.currentFoodItemObj['is_available'] == true){
      this.foodItemAvailable = true;
    }
    else{
      this.foodItemAvailable = false;
    }
    if(this.currentFoodItemObj['type']){
      if(this.currentFoodItemObj['type'] == 'Vegetarian'){
        this.currentFoodItemObj['type'] = 'Veg'
      }
      else if(this.currentFoodItemObj['type'] == 'Non-Vegetarian'){
        this.currentFoodItemObj['type'] = 'Non-Veg'
      }
      else if(this.currentFoodItemObj['type'] == 'Vegetarian-Contains Egg'){
        this.currentFoodItemObj['type'] = 'Veg-Contains Egg'
      }
      else{
        this.currentFoodItemObj['type'] = 'Vegan'
      }
    }
    else{
      this.currentFoodItemObj['type'] = null
    }
    let currentFoodItemMediaFile = this.currentFoodItemObj['hotel_menu_item_media_files'];
    this.isFoodItemMediaFileUpload = true;
    if(currentFoodItemMediaFile && currentFoodItemMediaFile.length > 0){
      for (let i = 0; i < currentFoodItemMediaFile.length; i++) {
        this.foodItemMediaFile.push({
          'localURL': currentFoodItemMediaFile[i]['url'],
          'id': currentFoodItemMediaFile[i]['id'],
          'url': currentFoodItemMediaFile[i]['url'],
          'status': 'successfully'
        })
      }
    }
  };

  updateMealItem() {
    let foodMediaID = [];
    if(this.foodItemMediaFile && this.foodItemMediaFile.length > 0){
      for (let i = 0; i < this.foodItemMediaFile.length; i++) {
        foodMediaID.push(this.foodItemMediaFile[i]['id'])
      }
    }
    let mealItemData = {
      'food_item_id': this.currentFoodItemObj['id'],
      'updated_by_id': this.currentHotelAdminUserData['id'],
      'name': this.currentFoodItemObj['name'],
      'hotel_menu_item_time_id': this.currentFoodItemObj['hotel_menu_item_time_id'],
      'hotel_menu_item_category_id': this.currentFoodItemObj['hotel_menu_item_category_id'],
      'hotel_menu_item_outlet_id': this.currentFoodItemObj['hotel_menu_item_outlet_id'],
      'price': this.currentFoodItemObj['price'],
      'tax_rate': this.currentFoodItemObj['tax_rate'],
      'preparation_time': this.currentFoodItemObj['preparation_time'],
      'is_available': this.foodItemAvailable,
      'food_item_media': [],
    }
    if(this.currentFoodItemObj['type']){
      if(this.currentFoodItemObj['type'] == 'Veg'){
        mealItemData['type'] = 'Vegetarian'
      }
      else if(this.currentFoodItemObj['type'] == 'Non-Veg'){
        mealItemData['type'] = 'Non-Vegetarian'
      }
      else if(this.currentFoodItemObj['type'] == 'Veg-Contains Egg'){
        mealItemData['type'] = 'Vegetarian-Contains Egg'
      }
      else{
        mealItemData['type'] = 'Vegan'
      }
    }
    else{
      mealItemData['type'] = ''
    }
    if(this.currentFoodItemObj['hotel_menu_item_time2_id']){
      mealItemData['hotel_menu_item_time2_id'] = this.currentFoodItemObj['hotel_menu_item_time2_id'];
    }
    if(this.currentFoodItemObj['ingredients']){
      mealItemData['ingredients'] = this.currentFoodItemObj['ingredients'];
    }
    if(this.currentFoodItemObj['special_price']){
      mealItemData['special_price'] = this.currentFoodItemObj['special_price'];
    }
    if (this.currentFoodItemObj['description']) {
      mealItemData['description'] = this.currentFoodItemObj['description'];
    }
    if(foodMediaID && foodMediaID.length > 0){
      mealItemData['food_item_media'] = foodMediaID;
    }

    this.hotelAdminPanelService.updateHotelFoodItem(mealItemData)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.errorObj = {};
            this.currentFoodItemObj = {};
            this.getHotelFoodItemList()
            this.foodItemUpdateModel.nativeElement.click();
            this.toastr.success(response['msg'], response['data']['name'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  removeFoodItem() {
    let data = {
      'food_item_id': this.currentFoodItemObj['id'],
    }
    this.hotelAdminPanelService.removeHotelFoodItem(data)
      .subscribe(
        response => {
          if (response['status'] == 1) {
            this.currentFoodItemObj = {};
            this.errorObj = {};
            this.getHotelFoodItemList();
            this.foodItemRemoveModel.nativeElement.click();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.currentFoodItemObj = {};
              this.errorObj = {};
              this.foodItemRemoveModel.nativeElement.click();
              this.toastr.error(response['msg'])
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  uploadFNBOutletBanner(event: EventEmitter<File[]>) {
    this.isOutletBannerFileUpload = false;
    const outletBannerFile: File = event[0];
    this.outletBanner = outletBannerFile
    var reader = new FileReader();
    reader.readAsDataURL(outletBannerFile);
    reader.onload = (_event) => {
      this.isOutletBannerFileUpload = true;
    }
  };

  isOutletActiveStatus(event) {
    if (event.target.checked) {
      this.outletActive = true;
    }
    else{
      this.outletActive = false;
    }
  }

  addFNBOutlet(){
    this.isOutletButtonInProcess = true;
    let formData: FormData = new FormData();
    formData.append('hotel_id', this.currentHotelAdminUserData['hotel_id'])
    formData.append('name', this.outletName)
    formData.append('tax', String(this.outletTax))
    formData.append('type', String(this.outletDeliveryMode))
    formData.append('is_active', String(this.outletActive))
    if(this.outletNumberOfTable){
      formData.append('number_of_table', String(this.outletNumberOfTable))
    }
    formData.append('description', this.outletDescription)
    formData.append('location_description', this.outletLocationDescription)
    if(this.outletDeliveryLocation){
      formData.append('delivery_location', this.outletDeliveryLocation)
    }
    formData.append('banner', this.outletBanner)
    this.hotelAdminPanelService.createHotelFNBOutlet(formData)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.toastr.success(response['msg'])
            this.clearFNBOutletData();
            this.getHotelFNBOutletList();
          }
          else{
            if(response['msg'] && response['status'] == 0){
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              this.toastr.error(response['msg'])
              this.isOutletButtonInProcess = false;
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );

  };

  showFNBOutletData(data) {
    this.currentFNBOutletObj = {};
    this.errorObj = {};
    this.currentFNBOutletObj = Object.assign({}, data);
    this.isOutletBannerFileUpload = true;
    this.outletBanner = null;
  };

  updateFNBOutlet(){
    this.isOutletButtonInProcess = true;
    let formData: FormData = new FormData();
    formData.append('hotel_id', this.currentHotelAdminUserData['hotel_id'])
    formData.append('outlet_id',this.currentFNBOutletObj['id'])
    formData.append('name',this.currentFNBOutletObj['name'])
    formData.append('tax', String(this.currentFNBOutletObj['tax']))
    formData.append('type', String(this.currentFNBOutletObj['type']))
    formData.append('is_active', String(this.outletActive))
    formData.append('description', this.currentFNBOutletObj['description'])
    formData.append('location_description', this.currentFNBOutletObj['location_description'])
    if(this.outletBanner != undefined || this.outletBanner != null){
      formData.append('banner', this.outletBanner)
    }
    else{
      formData.append('banner', this.outletBanner)
    }
    if(this.currentFNBOutletObj['number_of_table']){
      formData.append('number_of_table', String(this.currentFNBOutletObj['number_of_table']))
    }
    if(this.currentFNBOutletObj['delivery_location']){
      formData.append('delivery_location', String(this.currentFNBOutletObj['delivery_location']))
    }
    this.hotelAdminPanelService.updateHotelFNBOutlet(formData)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.clearFNBOutletData();
            this.getHotelFNBOutletList();
            this.toastr.success(response['msg'])
          }
          else{
            if(response['msg'] && response['status'] == 0){
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              this.toastr.error(response['msg'])
              this.isOutletButtonInProcess = false;
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );

  };

  removeFNBOutlet() {
    let data = {
      'outlet_id': this.currentFNBOutletObj['id'],
    }
    this.hotelAdminPanelService.removeHotelFNBOutlet(data)
      .subscribe(
        response => {
          if (response['status'] == 1) {
            this.errorObj = {};
            this.currentFNBOutletObj = {};
            this.getHotelFNBOutletList()
            this.fnbOutletRemoveModel.nativeElement.click();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  addFNBOutletTable() {
    let data = {
      'hotel_fnb_outlet_id': this.currentSelectedOutletID,
      'table_number': this.outletTableNumber,
      'person_capacity': this.outletTablePersonCapacity,
    }
    this.hotelAdminPanelService.createHotelFNBOutletTable(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.getHotelFNBOutletTableList()
            this.toastr.success(response['msg'])
            this.clearFNBOutletTableData()
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  setCurrentFNBTableDetail(currentFNBTable){
    this.errorObj = {};
    this.errorMessage = null;
    this.currentSelectedOutletTable = {}
    if(currentFNBTable){
      this.currentSelectedOutletTable =  Object.assign({}, currentFNBTable);
    }
  };

  updateFNBOutletTable() {
    let mealItemData = {
      'hotel_fnb_outlet_table_id': this.currentSelectedOutletTable['id'],
      'hotel_fnb_outlet_id': this.currentSelectedOutletID,
      'table_number': this.currentSelectedOutletTable['table_number'],
      'person_capacity': this.currentSelectedOutletTable['person_capacity'],
    }
    this.hotelAdminPanelService.updateHotelFNBOutletTable(mealItemData)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.errorObj = {};
            this.currentSelectedOutletTable = {};
            this.getHotelFNBOutletTableList()
            this.fnbOutletTableUpdateModel.nativeElement.click();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  onOutletTypeChange(type){
    if(type == 'Dine-In'){
      this.outletNumberOfTable = null;
      if(this.currentFNBOutletObj && 'number_of_table' in this.currentFNBOutletObj){
        this.currentFNBOutletObj['number_of_table'] = null;
        this.currentFNBOutletObj['delivery_location'] = '';
      }
    }
    else if(type == 'Delivery'){
      this.outletDeliveryLocation = '';
      if(this.currentFNBOutletObj && 'delivery_location' in this.currentFNBOutletObj){
        this.currentFNBOutletObj['delivery_location'] = '';
        this.currentFNBOutletObj['number_of_table'] = null;
      }
    }
  };

  removeFNBOutletTable() {
    let mealItemData = {
      'hotel_fnb_outlet_table_id': this.currentSelectedOutletTable['id'],
    }
    this.hotelAdminPanelService.removeHotelFNBOutletTable(mealItemData)
      .subscribe(
        response => {
          if (response['status'] == 1) {
            this.errorObj = {};
            this.currentSelectedOutletTable = {};
            this.getHotelFNBOutletTableList()
            this.fnbOutletTableRemoveModel.nativeElement.click();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

}
