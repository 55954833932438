import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import { HotelAdminPanelService } from '../../hotel-admin-panel.service';

@Component({
  selector: 'app-hotel-admin-top-navigation',
  templateUrl: './hotel-admin-top-navigation.component.html',
  styleUrls: ['./hotel-admin-top-navigation.component.css']
})
export class HotelAdminTopNavigationComponent implements OnInit {
  hotelUserData = {};
  constructor(
    private router: Router,
    private hotelAdminPanelService: HotelAdminPanelService
  ) { }

  ngOnInit() {
    this.getHotelSessionDetail();
  }

  getHotelSessionDetail(){
    let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
    if(response['status'] == 1){
      this.hotelUserData = response['hotelUserData']
    }
  };

  logOut(){
    let response = this.hotelAdminPanelService.clearHotelAdminUserSessionData()
    if(response['status'] == 1){
      this.router.navigate(['/login'])
    }
  };

}
