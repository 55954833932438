// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:{
   apiKey: 'AIzaSyC8SVz-ofO10wZPCWHJIM-GEso-oxN3pc8',
    authDomain: 'whyte-smart-home-beta.firebaseapp.com',
    databaseURL: 'https://whyte-smart-home-beta.firebaseio.com',
    projectId: 'whyte-smart-home-beta',
    storageBucket: 'whyte-smart-home-beta.appspot.com',
    messagingSenderId: '343717156529',
    appId: '1:343717156529:web:843c29e420dfe8b64db490',
    vapidKey: 'BF1d-o0g0pTBe_zgZow4NSXIPyP83b_MF6nv7V1LgyHQQuEzP9JaZsgo2xQIK2elYEFfw5Ciee-_d9L7xK7qask'
  },
  apiBaseURL: 'https://whyte-hotels.com',
  webSoketBaseURL: 'wss://whyte-hotels.com:8001',
  hotelChainBaseURL: 'https://whyte-hotels.com',
  // apiBaseURL: 'http://159.89.170.1',
  // webSoketBaseURL: 'ws://159.89.170.1:8001',
  // hotelChainBaseURL: 'http://159.89.170.1',
  // apiBaseURL: 'http://localhost:8080',
  // webSoketBaseURL: 'ws://localhost:8080',
  // hotelChainBaseURL: 'http://localhost:8080',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
