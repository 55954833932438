import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {Router} from "@angular/router"
import { ToastrService } from 'ngx-toastr';
import { HotelAdminPanelService } from './../hotel-admin-panel.service';

@Component({
  selector: 'app-hotel-admin-change-password',
  templateUrl: './hotel-admin-change-password.component.html',
  styleUrls: ['./hotel-admin-change-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HotelAdminChangePasswordComponent implements OnInit {
  hotelUserData = {};
  currentActiveRoute: string
  currentPassword: string = null;
  newPassword: string = null;
  confirmNewPassword: string = null;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private hotelAdminPanelService: HotelAdminPanelService
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/admin/design-common.js");
    this.currentActiveRoute = this.router.url;
    this.getHotelSessionDetail();
  }

  public loadJsFile(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('body')[0].appendChild(node);  
  }  

  getHotelSessionDetail(){
    let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
    if(response['status'] == 1){
      this.hotelUserData = response['hotelUserData']
    }
  };

  changePassword(){
    let data = {
      'current_password': this.currentPassword, 
      'new_password': this.newPassword, 
      'hotel_authority_id': this.hotelUserData['id']
    }
    this.hotelAdminPanelService.changeHotelAdminPassword(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.toastr.success(response['msg'])
            this.router.navigate(['/hotel-admin/room-management'])
          }
          else{
            if(response['msg'] && response['status'] == 0){
              this.toastr.error('Password incorrect')
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

}