import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  ViewEncapsulation
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { GuestPanelService } from '../guest-panel.service';

@Component({
  selector: 'app-room-service-request',
  templateUrl: './room-service-request.component.html',
  styleUrls: ['./room-service-request.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RoomServiceRequestComponent implements OnInit {
  webSocket: WebSocketSubject<any>;
  messages: Observable<any>;
  subscription: Subscription;
  hotelGuestUserData: object = {};
  hotelRoomServiceList = [];
  currentRoomRequestData: object = {};
  errorMessage: string = '';
  errorObj = {}
  roomServiceInstruction: string = '';
  @ViewChild('roomServiceRequestCreateModel', { read: ElementRef, static: false }) public roomServiceRequestCreateModel: ElementRef;
  @ViewChild('roomServiceRequestDetailModel', { read: ElementRef, static: false }) public roomServiceRequestDetailModel: ElementRef;

  constructor(
    private toastr: ToastrService,
    private guestPanelService: GuestPanelService
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/guest/design-common.js");
    this.getGuestSessionDetail();
    this.webSocket = webSocket(environment.webSoketBaseURL + '/hotel-chain/');
    this.webSocket.asObservable().subscribe(dataFromServer => {
      if (dataFromServer['event'] == 'update_room_service_request_status' &&
        dataFromServer['data'] &&
        dataFromServer['data']['stay_id'] == this.hotelGuestUserData['guest_detail']['stay_id']) {
        if(dataFromServer['data']['room_service_request_status_id'] == 2){
          this.toastr.info('your room service request is accepted',
            dataFromServer['data']['room_service_name'])
        }
        if(dataFromServer['data']['room_service_request_status_id'] == 3){
          this.toastr.info('your room service successfully fulfilled',
            dataFromServer['data']['room_service_name'])
        }
      }
    });
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  getGuestSessionDetail() {
    let response = this.guestPanelService.getHotelGuestUserSessionData()
    if (response['status'] == 1) {
      this.hotelGuestUserData = response['hotelGuestUserData']
      this.getHotelRoomServiceList();
    }
  };

  getHotelRoomServiceList() {
    this.hotelRoomServiceList = [];
    this.guestPanelService.getHotelRoomServiceList(
      { 'hotel_id': this.hotelGuestUserData['guest_detail']['hotel_id'] })
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.hotelRoomServiceList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  placeRoomServiceRequest() {
    let data = {
      'hotel_id': this.hotelGuestUserData['guest_detail']['hotel_id'],
      'stay_id': this.hotelGuestUserData['guest_detail']['stay_id'],
      'room_service_id': this.currentRoomRequestData['id'],
      'request_at_timestamp': Math.round(+new Date() / 1000)
    }
    if(this.roomServiceInstruction != '' && this.roomServiceInstruction != null){
      data['special_instructions'] = this.roomServiceInstruction
    }
    this.guestPanelService.placeRoomServiceRequest(data)
      .subscribe(
        response => {
          if (response['status'] == 1) {
            this.roomServiceRequestDetailModel.nativeElement.click();
            this.roomServiceRequestCreateModel.nativeElement.click();
            this.roomServiceInstruction = '';
            this.toastr.success(response['msg'])
            this.currentRoomRequestData = {}
          }
          else {
            this.roomServiceRequestCreateModel.nativeElement.click();
            this.toastr.error(response['msg'])
            this.currentRoomRequestData = {}
            this.roomServiceInstruction = '';
          }
        },
        error => {
          console.log(error);
        }
      );
  };

}
