import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router"
import { ToastrService } from 'ngx-toastr';
import { StaffFrontDeskPanelService } from '../staff-front-desk-panel.service';

@Component({
  selector: 'app-staff-change-password',
  templateUrl: './staff-change-password.component.html',
  styleUrls: ['./staff-change-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StaffChangePasswordComponent implements OnInit {
  hotelStaffUserData = {};
  currentActiveRoute: string
  currentPassword: string = null;
  newPassword: string = null;
  confirmNewPassword: string = null;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private staffFrontDeskPanelService: StaffFrontDeskPanelService
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/frontdesk/design-common.js");
    this.getHotelSessionDetail();
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  getHotelSessionDetail(){
    let response = this.staffFrontDeskPanelService.getHotelStaffUserSessionData()
    if(response['status'] == 1){
      this.hotelStaffUserData = response['hotelStaffUserData']
    }
  };

  changePassword(){
    let data = {
      'current_password': this.currentPassword, 
      'new_password': this.newPassword, 
      'staff_id': this.hotelStaffUserData['id']
    }
    this.staffFrontDeskPanelService.changePassword(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.toastr.success(response['msg'])
            this.router.navigate(['/front-desk/guest-panel'])
          }
          else{
            if(response['msg'] && response['status'] == 0){
              this.toastr.error('Password incorrect')
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };
  
}
