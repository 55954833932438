import {
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StaffFrontDeskPanelService } from '../staff-front-desk-panel.service';

@Component({
  selector: 'app-guest-room-stay-details',
  templateUrl: './guest-room-stay-details.component.html',
  styleUrls: ['./guest-room-stay-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GuestRoomStayDetailsComponent implements OnInit {
  currentHotelStaffUserData = {};
  roomList: any[] = [];
  errorObj = {};

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private staffFrontDeskPanelService: StaffFrontDeskPanelService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/frontdesk/design-common.js");
    this.getHotelStaffSessionDetail()
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  getRoomList() {
    this.staffFrontDeskPanelService.getRoomListByHotel({'hotel_id': this.currentHotelStaffUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.roomList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelStaffSessionDetail(){
    let response = this.staffFrontDeskPanelService.getHotelStaffUserSessionData()
    if(response['status'] == 1){
      this.currentHotelStaffUserData = response['hotelStaffUserData']
      this.getRoomList()
    }
  };

}
