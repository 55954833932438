import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  ViewEncapsulation
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router"
import { HotelAdminPanelService } from '../hotel-admin-panel.service';

@Component({
  selector: 'app-room-management',
  templateUrl: './room-management.component.html',
  styleUrls: ['./room-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RoomManagementComponent implements OnInit {
  @ViewChild('wingCreateModel', { read: ElementRef, static:false }) public wingCreateModel: ElementRef;
  @ViewChild('wingUpdateModel', { read: ElementRef, static:false }) public wingUpdateModel: ElementRef;
  @ViewChild('wingDeleteModel', { read: ElementRef, static:false }) public wingDeleteModel: ElementRef;
  @ViewChild('roomCreateModel', { read: ElementRef, static:false }) public roomCreateModel: ElementRef;
  @ViewChild('roomUpdateModel', { read: ElementRef, static:false }) public roomUpdateModel: ElementRef;
  @ViewChild('roomDeleteModel', { read: ElementRef, static:false }) public roomDeleteModel: ElementRef;
  @ViewChild('roomTypeRemoveModel', { read: ElementRef, static:false }) public roomTypeRemoveModel: ElementRef;
  currentHotelAdminUserData = {};
  globalRoomTypeList = [];
  hotelRoomTypeList = [];
  currentSelectedRoomType = {};
  tempHotelRoomType = {};
  wingList = [];
  wingRoomList = [];
  wingName: string = '';
  wingLocation: string = '';
  wingNumberOfFloors: number;
  wingNumberOfRooms: number;
  errorMessage: string = '';
  roomTypeID: number = null;
  wingID: number = null;
  roomNumber: number;
  roomFloorNumber: Number;
  roomDescription: string = ''
  isRoomAvailable: boolean = true;
  isRoomFloorError: boolean = false;
  errorObj = {};
  currentWingObj = {};
  currentRoomObj: object = {};
  currentHotelRoomType = {};
  wingFloorList: any[] = [];

  constructor(
    private toastr: ToastrService,
    private renderer: Renderer2,
    private hotelAdminPanelService: HotelAdminPanelService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/admin/design-common.js");
    this.getHotelAdminUserSessionDetail();
  }

  public loadJsFile(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('body')[0].appendChild(node);  
  }

  getHotelAdminUserSessionDetail(){
    let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
    if(response['status'] == 1){
      this.currentHotelAdminUserData = response['hotelUserData']
      this.getGlobalRoomTypeList();
      this.getHotelRoomTypeList();
      this.getWingRoomList();
      this.getHotelWingList();
    }
  };

  getGlobalRoomTypeList(){
    this.hotelAdminPanelService.getGlobalRoomTypeList({})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.globalRoomTypeList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelRoomTypeList(){
    this.hotelRoomTypeList = [];
    this.hotelAdminPanelService.getRoomTypeListByHotel({'hotel_id': this.currentHotelAdminUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.hotelRoomTypeList = response['data']
            if(this.hotelRoomTypeList && this.hotelRoomTypeList.length > 0){
              this.globalRoomTypeList = this.globalRoomTypeList.filter(k=>!this.hotelRoomTypeList.some(p=>p.name==k.name));
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  globalRoomTypeSelection(roomType){
    if(roomType){
      this.currentSelectedRoomType = roomType;
    }
  };

  addGlobalRoomType(){
    if(this.currentSelectedRoomType && 'id' in this.currentSelectedRoomType){
      let currentSelectedRoomTypeObj = 
        this.hotelRoomTypeList.find(o => o.global_room_type_id === this.currentSelectedRoomType['id']);
      const index = this.hotelRoomTypeList.indexOf(currentSelectedRoomTypeObj);
      if (index > -1) {
        this.toastr.error("Room Type already exists", this.currentSelectedRoomType['name'])
      }
      else{
        if(this.tempHotelRoomType){
          const index = this.hotelRoomTypeList.indexOf(this.tempHotelRoomType);
          if (index > -1) {
            this.hotelRoomTypeList.splice(index, 1);
          }
        }
        this.tempHotelRoomType = JSON.parse(JSON.stringify(this.currentSelectedRoomType));
        this.currentSelectedRoomType = {}
        this.hotelRoomTypeList.push(this.tempHotelRoomType)
      }
    }
  };

  removeTempHotelRoomType(){
    if(this.tempHotelRoomType && 'id' in this.tempHotelRoomType){
      let currentSelectedRoomTypeObj = 
        this.hotelRoomTypeList.find(o => o.id === this.tempHotelRoomType['id']);
      const index = this.hotelRoomTypeList.indexOf(currentSelectedRoomTypeObj);
      if (index > -1) {
        this.hotelRoomTypeList.splice(index, 1);
        this.tempHotelRoomType = {}
      }
    }
  };

  createHotelRoomType(){
    let data = {
      'hotel_id': this.currentHotelAdminUserData['hotel_id'],
      'global_room_type_id': this.tempHotelRoomType['id']
    }
    this.hotelAdminPanelService.createHotelRoomType(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.tempHotelRoomType = {};
            this.toastr.success(response['msg'], response['data']['name'])
            this.getHotelRoomTypeList();
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelWingList(){
    this.wingList = [];
    this.hotelAdminPanelService.getWingListByHotel({'hotel_id': this.currentHotelAdminUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.wingList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getWingRoomList(){
    this.wingRoomList = [];
    this.hotelAdminPanelService.getHotelWingRoomList({'hotel_id': this.currentHotelAdminUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.wingRoomList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  clearWingData(){
    this.wingCreateModel.nativeElement.click();
    this.wingUpdateModel.nativeElement.click();
    setTimeout(()=>{  
      this.wingName = '';
    this.wingLocation = '';
      this.wingNumberOfFloors = null;
      this.wingNumberOfRooms = null;
      this.errorMessage =  '';
      this.errorObj = {};
      this.currentWingObj = {}; 
    }, 1000)
   this.getHotelWingList();
    // this.renderer.removeClass(this.wingCreateModel.nativeElement, 'model-backdrop');
    // this.renderer.removeClass(this.wingCreateModel.nativeElement, 'show');
    // this.renderer.setStyle(this.wingCreateModel.nativeElement, 'display', 'none');
  };

  createHotelWing(){
    let data = {
      'hotel_id': this.currentHotelAdminUserData['hotel_id'],
      'name': this.wingName,
      'location': this.wingLocation,
      'number_of_floors': this.wingNumberOfFloors,
      'number_of_rooms': this.wingNumberOfRooms
    }
    this.hotelAdminPanelService.createHotelWing(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.clearWingData();
            this.toastr.success(response['msg'], response['data']['name'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  selectWing(wingObj){
    this.errorObj = {};
    this.errorMessage = null;
    if(wingObj){
      this.currentWingObj = Object.assign({}, wingObj);
    }
    else{
      this.currentWingObj = {};
    }
  }

  updateHotelWing(){
    let data = {
      'wing_id': this.currentWingObj['id'],
      'name': this.currentWingObj['name'],
      'location': this.currentWingObj['location'],
      'number_of_floors': this.currentWingObj['number_of_floors'],
      'number_of_rooms': this.currentWingObj['number_of_rooms']
    }
    this.hotelAdminPanelService.updateHotelWing(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.getHotelWingList();
            this.clearWingData();
            this.toastr.success(response['msg'], response['data']['name'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  removeHotelWing(){
    let data = {
      'wing_id': this.currentWingObj['id']
    }
    this.hotelAdminPanelService.removeHotelWing(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.getHotelWingList();
            this.currentWingObj = {};
            this.errorObj = {};
            this.wingDeleteModel.nativeElement.click();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.currentWingObj = {};
              this.errorObj = {};
              this.wingDeleteModel.nativeElement.click();
              this.toastr.error(response['msg'])
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  clearRoomData(){
    this.roomCreateModel.nativeElement.click();
    this.roomUpdateModel.nativeElement.click();
    setTimeout(()=>{  
      this.roomTypeID = null;
      this.wingID = null;
      this.roomNumber = null;
      this.roomFloorNumber = null;
      this.isRoomFloorError = false;
      this.roomDescription =  '';
      this.isRoomAvailable = true;
      this.errorMessage =  '';
      this.errorObj = {};
      this.wingFloorList = [];
    }, 1000)
  };

  isRoomAvailableStatus(event) {
    if (event.target.checked) {
      this.isRoomAvailable = true;
    }
    else{
      this.isRoomAvailable = false;
    }
  }

  createHotelRoom(){
    let data = {
      'hotel_id': this.currentHotelAdminUserData['hotel_id'],
      'room_type_id': this.roomTypeID,
      'wing_id': this.wingID,
      'room_number': this.roomNumber,
      'room_floor_number': this.roomFloorNumber,
      'is_available': this.isRoomAvailable,
      'description': this.roomDescription
    }
    this.hotelAdminPanelService.createHotelRoom(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.clearRoomData();
            this.toastr.success(response['msg'], response['data']['room_number'])
            this.getWingRoomList();
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  selectRoom(roomObj){
    this.errorObj = {};
    this.errorMessage = null;
    this.wingFloorList = [];
    if(roomObj){
      this.currentRoomObj = Object.assign({}, roomObj);
      if('wing_id' in this.currentRoomObj){
        let currentWingObj = this.wingList.find(o => o.id == this.currentRoomObj['wing_id']);
        if(currentWingObj && 'number_of_floors'in currentWingObj){
          for(let i=0; i<Number(currentWingObj['number_of_floors']); i++){
            this.wingFloorList.push(i)
          }
        }
      }
      if(this.currentRoomObj['is_available'] == true){
        this.isRoomAvailable = true;
      }
      else{
        this.isRoomAvailable = false;
      }
    }
    else{
      this.currentRoomObj = {};
      this.isRoomAvailable = true;
    }
  };

  updateHotelRoom(){
    let data = {
      'room_id': this.currentRoomObj['id'],
      'room_type_id': this.currentRoomObj['room_type_id'],
      'wing_id': this.currentRoomObj['wing_id'],
      'room_number': this.currentRoomObj['room_number'],
      'room_floor_number': this.currentRoomObj['room_floor_number'],
      'is_available': this.isRoomAvailable,
      'description': this.currentRoomObj['description']
    }
    this.hotelAdminPanelService.updateHotelRoom(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.clearRoomData();
            this.toastr.success(response['msg'], response['data']['room_number'])
            this.getWingRoomList();
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
    );
  };

  removeRoom(){
    let data = {
      'room_id': this.currentRoomObj['id']
    }
    this.hotelAdminPanelService.removeHotelRoom(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.getWingRoomList();
            this.currentRoomObj = {};
            this.errorObj = {};
            this.roomUpdateModel.nativeElement.click();
            this.roomDeleteModel.nativeElement.click();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              this.currentRoomObj = {};
              this.errorObj = {};
              this.roomUpdateModel.nativeElement.click();
              this.roomDeleteModel.nativeElement.click();
              this.toastr.error(response['msg'])
            }
          }
        },
        error => {
          console.log(error);
        }
    );
  };

  cancleRoomRemoveModel(){
    this.roomDeleteModel.nativeElement.click();
    this.roomUpdateModel.nativeElement.click();
    this.currentRoomObj = {};
    this.errorObj = {};
  }

  currentSelectionSection(sectionName){
    if(sectionName == 'room-type'){
      this.getGlobalRoomTypeList();
      this.getHotelRoomTypeList();
    }
    else if(sectionName == 'wing'){
      this.getWingRoomList();
    }
    else if(sectionName == 'room'){
      this.getHotelRoomTypeList();
      this.getWingRoomList();
      this.getHotelWingList();
    }
  }

  validateRoomNumberAndRoomFloorDetail(wingID, roomFloorNumber){
    this.isRoomFloorError = false;
    if(wingID){
      let currentWingObj = this.wingList.find(o => o.id == wingID);
      if(currentWingObj && roomFloorNumber){
        if(currentWingObj['number_of_floors'] < Number(roomFloorNumber)){
          this.isRoomFloorError = true;
        }
      }
    }
  }

  getWingFloorNumberList(wingID){
    this.wingFloorList = [];
    if(wingID){
      this.roomFloorNumber = null;
      if(this.currentRoomObj && 'room_floor_number' in this.currentRoomObj){
        this.currentRoomObj['room_floor_number'] = null;
      }
      let currentWingObj = this.wingList.find(o => o.id == wingID);
      if(currentWingObj && 'number_of_floors'in currentWingObj){
        for(let i=0; i<Number(currentWingObj['number_of_floors']); i++){
          this.wingFloorList.push(i)
        }
      }
    }
  };

  removeRoomType(){
    let data = {
      'room_type_id': this.currentHotelRoomType['id'],
    }
    this.hotelAdminPanelService.removeHotelRoomType(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.currentHotelRoomType = {};
            this.errorObj = {};
            this.getGlobalRoomTypeList();
            this.getHotelRoomTypeList();
            this.roomTypeRemoveModel.nativeElement.click();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.roomTypeRemoveModel.nativeElement.click();
              this.toastr.error(response['msg'])

            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };
}
