import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff-panel-bottom-navigation',
  templateUrl: './staff-panel-bottom-navigation.component.html',
  styleUrls: ['./staff-panel-bottom-navigation.component.css']
})
export class StaffPanelBottomNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
