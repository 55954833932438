import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import { StaffFrontDeskPanelService } from '../../staff-front-desk-panel.service';

@Component({
  selector: 'app-staff-panel-left-navigation',
  templateUrl: './staff-panel-left-navigation.component.html',
  styleUrls: ['./staff-panel-left-navigation.component.css']
})
export class StaffPanelLeftNavigationComponent implements OnInit {

  hotelStaffUserData = {};
  currentActiveRoute: string
  isFoodBeverageEnabled: boolean = false

  constructor(
    private router: Router,
    private staffFrontDeskPanelService: StaffFrontDeskPanelService
  ) { }

  ngOnInit() {
    this.currentActiveRoute = this.router.url;
    this.getHotelSessionDetail();
  }

  getHotelSessionDetail(){
    let response = this.staffFrontDeskPanelService.getHotelStaffUserSessionData()
    if(response['status'] == 1){
      this.hotelStaffUserData = response['hotelStaffUserData']
      this.getStaffDetail();
      // this.isFoodBeverageEnableServiceStaff()
    }
  };

  getStaffDetail(){
    this.staffFrontDeskPanelService.getHotelStaffUserDetail({'staff_id': this.hotelStaffUserData['id']})
    .subscribe(
      response => {
        if(response['status'] === 1 && response['data'] && response['data']['is_front_desk_user'] == true){
          let data = response['data']
          this.hotelStaffUserData = response['data']
          if(this.hotelStaffUserData && 'hotel_department_name' in this.hotelStaffUserData && 
          this.hotelStaffUserData['hotel_department_name'] == 'Food & Beverages'){
            this.isFoodBeverageEnabled = true;
          }
          else{
            this.isFoodBeverageEnabled = false;
          }
          let result = this.staffFrontDeskPanelService.storeHotelStaffUserSessionData(data, true)
        }
      },
      error => {
        console.log(error);
      }
    );
  };

  // isFoodBeverageEnableServiceStaff(){
  //   this.staffFrontDeskPanelService.isFoodBeverageServiceEnable({'hotel_id': this.hotelStaffUserData['hotel_id']})
  //   .subscribe(
  //     response => {
  //       if(response['is_enable'] == true){
  //         this.isFoodBeverageEnabled = true;
  //       }
  //       else{
  //         this.isFoodBeverageEnabled = false;
  //       }
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // };

}
