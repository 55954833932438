import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  ViewEncapsulation
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { Router } from "@angular/router"
import {Howl, Howler} from 'howler';
import { environment } from 'src/environments/environment';
import { StaffFrontDeskPanelService } from '../staff-front-desk-panel.service';


@Component({
  selector: 'app-guest-meal-order-request',
  templateUrl: './guest-meal-order-request.component.html',
  styleUrls: ['./guest-meal-order-request.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GuestMealOrderRequestComponent implements OnInit {
  @ViewChild('mealOrderRequestHistoryModel', { read: ElementRef, static:false }) public mealOrderRequestHistoryModel: ElementRef;
  webSocket: WebSocketSubject<any>;
  messages: Observable<any>;
  subscription: Subscription;
  currentHotelStaffUserData = {};
  currentFoodRequestStatusType = Number
  mealOrderRequestList: any[] = [];
  mealOrderRequestHistoryList: any[] = [];
  mealOrderStatusList: any[]= [
    {'id': 1, 'name': 'Received'}, 
    {'id': 2, 'name': 'Preparing'}, 
    {'id': 3, 'name': 'On The Way'}, 
    {'id': 4, 'name': 'Delivered'}]
  filterByDay: Number = null;
  // sound = new Wad({
  //   source : '../../../assets/sound/notification.mp3',
  //   loop: true
  // });
  sound = new Howl({
    src: ['../../../assets/sound/notification.mp3'],
    html5: true,
    autoplay: false,
    loop: true,
  });
  constructor(
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private staffFrontDeskPanelService: StaffFrontDeskPanelService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/frontdesk/design-common.js");
    this.getHotelStaffSessionDetail()
    this.webSocket = webSocket(environment.webSoketBaseURL + '/hotel-chain/');
    this.webSocket.asObservable().subscribe(dataFromServer => {
      this.sound.stop();
      if(dataFromServer['event'] == 'receive_new_food_order_request' && 
        dataFromServer['data'] && 
        dataFromServer['data']['hotel_id'] == this.currentHotelStaffUserData['hotel_id']){
        this.getHotelStaffSessionDetail()
        this.getCurrentMealOrderRequestList();
        if(this.currentHotelStaffUserData && 'hotel_department_name' in this.currentHotelStaffUserData && 
          this.currentHotelStaffUserData['hotel_department_name'] == 'Food & Beverages'){
            this.sound.play();
        }
        this.toastr.info('New Meal Request Received', 
            'Room No: ' + dataFromServer['data']['wing_name'] + '-' + dataFromServer['data']['room_number']
          )
      }

      if(dataFromServer['event'] == 'update_food_order_request_status' && 
        dataFromServer['data'] && 
        dataFromServer['data']['hotel_id'] == this.currentHotelStaffUserData['hotel_id']){
          this.getCurrentMealOrderRequestList();
      }

      if(dataFromServer['event'] == 'cancel_food_order_request_by_guest' && 
        dataFromServer['data'] && 
        dataFromServer['data']['hotel_id'] == this.currentHotelStaffUserData['hotel_id']){
          this.getCurrentMealOrderRequestList();
      }
      if(dataFromServer['event'] == 'remove_food_order_request'){
        this.getCurrentMealOrderRequestList();
      }
    });
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  getHotelStaffSessionDetail(){
    let response = this.staffFrontDeskPanelService.getHotelStaffUserSessionData()
    if(response['status'] == 1){
      this.currentHotelStaffUserData = response['hotelStaffUserData']
      this.getCurrentMealOrderRequestList();
      this.getMealOrderRequestHistoryList();
    }
  };

  getCurrentMealOrderRequestList(){
    this.httpClient.post(
      environment.hotelChainBaseURL + '/api/staff/get-hotel-food-ird-order-request-by-hotel/',
      {'hotel_id': this.currentHotelStaffUserData['hotel_id']}
    ).subscribe(response => {
        if(response['status'] == 1){
          this.mealOrderRequestList = response['data'];
        }
    }, error=>{
      console.log(error)
    })
  };

  updateCurrentOrederMealStatus(status, currentMealOrderRequestData){
    let data = {
      'order_id': currentMealOrderRequestData.order_id,
      'staff_id': this.currentHotelStaffUserData['id'],
      'food_request_status_type_id': String(status),
      'current_timestamp': Math.round(+new Date()/1000)
    }
    this.httpClient.post(
      environment.hotelChainBaseURL + '/api/staff/update-hotel-food-ird-order-status/',
      data
    ).subscribe(response => {
        if(response['status'] == 1){
          this.toastr.info('Food order status successfully updated', 
          'Order No: ' + currentMealOrderRequestData['order_id'])
        }
        else{
          this.getCurrentMealOrderRequestList();
          this.toastr.error(response['msg'])
        }
    }, error=>{
      console.log(error)
    })
  };

  getMealOrderRequestHistoryList(){
    this.filterByDay = null;
    this.mealOrderRequestHistoryList = []
    this.httpClient.post(
      environment.hotelChainBaseURL + '/api/staff/get-hotel-food-ird-order-request-histories-by-hotel/',
      {'hotel_id': this.currentHotelStaffUserData['hotel_id']}
    ).subscribe(response => {
        if(response['status'] == 1){
          this.mealOrderRequestHistoryList = response['data'];
        }
    }, error=>{
      console.log(error)
    })
  };

  getMealOrderRequestHistoryListByDate(days){
    if(days){
      this.mealOrderRequestHistoryList = []
      this.httpClient.post(
        environment.hotelChainBaseURL + '/api/staff/get-hotel-food-ird-order-request-histories-by-hotel/',
        {'hotel_id': this.currentHotelStaffUserData['hotel_id'], 'filter_by_day': Number(days)}
      ).subscribe(response => {
          if(response['status'] == 1){
            this.mealOrderRequestHistoryList = response['data'];
          }
      }, error=>{
        console.log(error)
      })
    };
  }

  onPageClick(){
    this.sound.stop();
    this.sound.unload();
  };

  ngOnDestroy(){
    this.sound.stop();
    this.sound.unload();
  };
}
