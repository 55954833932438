import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import { GuestPanelService } from '../../guest-panel.service';

@Component({
  selector: 'app-guest-panel-top-navigation',
  templateUrl: './guest-panel-top-navigation.component.html',
  styleUrls: ['./guest-panel-top-navigation.component.css']
})
export class GuestPanelTopNavigationComponent implements OnInit {
  hotelGuestUserData;
  constructor(
    private router: Router,
    private guestPanelService: GuestPanelService
  ) { }

  ngOnInit() {
    this.getGuestSessionDetail();
  }

  getGuestSessionDetail(){
    let response = this.guestPanelService.getHotelGuestUserSessionData()
    if(response['status'] == 1){
      this.hotelGuestUserData = response['hotelGuestUserData']
    }
  };

  logOut(){
    let response = this.guestPanelService.clearHotelGuestUserSessionData()
    if(response['status'] == 1){
      this.router.navigate(['/guest/scan-qr'])
    }
  };

}
