import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'userFriendlyTime'})
export class UserFriendlyTimePipe implements PipeTransform {
  transform(value: string): string {
    let timeStamp = parseInt(value)
    let someDate = new Date(timeStamp);
    let today = new Date(); 
    if(someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()){
            return 'Today'
    }
    else{
        someDate.toLocaleString();
        let DateTimeString = someDate.toString();
        var index = DateTimeString.lastIndexOf(':') + 3
        return DateTimeString.substring(0, index);
    }
  }
}