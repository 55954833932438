import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  ViewEncapsulation
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import { GuestPanelService } from '../guest-panel.service';

@Component({
  selector: 'app-appliances',
  templateUrl: './appliances.component.html',
  styleUrls: ['./appliances.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppliancesComponent implements OnInit {
  @ViewChild('fanEqipmentModel', { read: ElementRef, static: false }) public fanEqipmentModel: ElementRef;
  @ViewChild('acEqipmentModel', { read: ElementRef, static: false }) public acEqipmentModel: ElementRef;
  @ViewChild('stvEqipmentModel', { read: ElementRef, static: false }) public stvEqipmentModel: ElementRef;
  @ViewChild('tvEqipmentModel', { read: ElementRef, static: false }) public tvEqipmentModel: ElementRef;
  webSocket: WebSocketSubject<any>;
  messages: Observable<any>;
  subscription: Subscription;
  hotelGuestUserData: object = {};
  currentFanEquipment: object = {};
  currentACEquipment: object = {};
  unitData: object = {};
  errorMessage: string = '';
  errorObj = {}
  unitAreaObjectList = [];
  httpOptions;
  boardHexList = [];
  currentBoardOperationDetailList = [];
  acEquipmentRefCodeList = [];
  tvEquipmentRefCodeList = [];
  stvEquipmentRefCodeList = [];
  acEquipmentRefCodeRemoteDataList =  [];
  tvEquipmentRefCodeRemoteDataList =  [];
  stvEquipmentRefCodeRemoteDataList =  [];
  stvChannelDataList = [];
  currentACModeList: [];
  currentBinaryStagesSwingList: [];
  currentACTemputure: number;
  currentSelectedACMode: string = '';
  currentSelectedACOnOffStatus: string = 'OFF'
  currentACBinarySwing: string = '';  
  currentBlasterACOperationDetailList = [];
  blasterACEquipmentTopicList = [];
  currentTVEquipment: object = {};
  currentSTVEquipment: object = {};
  unitAreaMoodTopicList: any[] = [];
  constructor(
    private toastr: ToastrService,
    private afAuth:  AngularFireAuth,
    private db: AngularFireDatabase,
    private guestPanelService: GuestPanelService
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/guest/design-common.js");
    this.getGuestSessionDetail();
    this.webSocket = webSocket(environment.webSoketBaseURL + '/mqtt/');
    this.webSocket.asObservable().subscribe(response => {
      if(response['event'] == 'send_unit_mqtt_data' && response['data']){
        console.log(response['data'])
        let mqttResponse = response['data']['payload'].split(' ').join('')
        let currentPreFix = mqttResponse.substring(0, 4);
        let currentPostFix = mqttResponse.substring(mqttResponse.length-4, mqttResponse.length);
        let currentCommand =  mqttResponse.substring(4, 6);
        let currentBoardID =  mqttResponse.substring(6, 18)
        let currentOperation = mqttResponse.substring(18, 20);
        let currentFanStatus = mqttResponse.substring(20, 22);
        let currentPayloadResponse = response['data']['payload'].split('/').join(',')
        let currentPayloadResponseList = currentPayloadResponse.split(',');
      
        if(currentCommand == 34 && this.boardHexList.includes(currentBoardID) == true){
          // console.log('Response: ' + mqttResponse)
          // console.log('PreFix: ' + currentPreFix)
          // console.log('PostFix: ' + currentPostFix)
          // console.log('ResponseCommand: ' + currentCommand)
          // console.log('BoardID: ' + currentBoardID)
          // console.log('Operation: ' + currentOperation)
          // console.log('Operation Binary: ' + this.hex2bin(currentOperation))
          // console.log('Fan Speed Status: ' + currentFanStatus)
          let operationDetailObj = {
            'boardHexID': currentBoardID,
            'currentEquipmentStatusInBinary': this.hex2bin(currentOperation),
            'fanSpeedStatus': currentFanStatus
          }
          if(this.currentBoardOperationDetailList && this.currentBoardOperationDetailList.length == 0){
            this.currentBoardOperationDetailList.push(operationDetailObj);
          }
          else{
            let currentOperationObj = this.currentBoardOperationDetailList.find(o => o.boardHexID == currentBoardID);
            if(currentOperationObj){
              let index = this.currentBoardOperationDetailList.indexOf(currentOperationObj);
              if (index > -1) {
                this.currentBoardOperationDetailList[index] = operationDetailObj;
              }
            }
            else{
              this.currentBoardOperationDetailList.push(operationDetailObj);
            }
          }
        }
        if(this.blasterACEquipmentTopicList.includes(response['data']['topic_name']) == true){
          let data  = {
            'topic_id': response['data']['topic_name'],
            'payload': JSON.parse(response['data']['payload'])
          }
          if(this.blasterACEquipmentTopicList && this.blasterACEquipmentTopicList.length == 0){
            this.currentBlasterACOperationDetailList.push(data);
          }
          else{
            let currentOperationObj = this.currentBlasterACOperationDetailList.find(o => o.topic_id == response['data']['topic_name']);
            if(currentOperationObj){
              let index = this.currentBlasterACOperationDetailList.indexOf(currentOperationObj);
              if (index > -1) {
                this.currentBlasterACOperationDetailList[index] = data;
              }
            }
            else{
              this.currentBlasterACOperationDetailList.push(data);
            }
          }
          if(this.currentACEquipment && 'area_object_id' in this.currentACEquipment && 
            'equipment_id' in this.currentACEquipment && 'minimum_temperature' in this.currentACEquipment){
            let unitID = this.hotelGuestUserData['unit_id'];
            let areaID = this.addLeadingZeros(this.currentACEquipment['area_object_id'], 12)
            let equipmentID = this.addLeadingZeros(this.currentACEquipment['equipment_id'], 12)
            let topicID = unitID + '/' + areaID +  '/' + equipmentID;
            let currentOperationObj = this.currentBlasterACOperationDetailList.find(o => o.topic_id == topicID);
            if(currentOperationObj && 'payload' in currentOperationObj){
              let currentOperationDetailObj = currentOperationObj['payload']
              if('onOff' in currentOperationDetailObj){
                this.currentSelectedACOnOffStatus = currentOperationDetailObj['onOff']
              }
              if('mode' in currentOperationDetailObj){
                this.currentSelectedACMode = currentOperationDetailObj['mode']
              }
              if('swing' in currentOperationDetailObj){
                this.currentACBinarySwing = currentOperationDetailObj['swing']
              }
              if('temperature' in currentOperationDetailObj){
                if(Number(currentOperationDetailObj['temperature']) == 0){
                  this.currentACTemputure = Number(this.currentACEquipment['minimum_temperature'])
                }
                else{
                  this.currentACTemputure = Number(currentOperationDetailObj['temperature'])
                }
              }
            }
          }
        }
        if(currentPayloadResponseList.includes('MOODS')){
          let moodName = currentPayloadResponseList[currentPayloadResponseList.length-1]
          let moodNameWithSpace = moodName.replace("_", " ");
          let responseObj = JSON.parse(JSON.stringify(response['data']))
          let topicName = responseObj['payload']
          let moodTopicObj  = {
            'mode_name': moodNameWithSpace,
            'topic_name': topicName,
            'payload': ''
          }
          console.log(moodTopicObj)
          if(this.unitAreaMoodTopicList && this.unitAreaMoodTopicList.length == 0){
            this.unitAreaMoodTopicList.push(moodTopicObj);
            this.sendUnitTopicListToWebSocket([topicName])
          }
          else{
            let currentMoodObj = this.unitAreaMoodTopicList.find(o => o.topic_name == topicName);
            if(currentMoodObj){
              let index = this.unitAreaMoodTopicList.indexOf(currentMoodObj);
              if (index > -1) {
                this.unitAreaMoodTopicList[index] = currentMoodObj;
              }
            }
            else{
              this.unitAreaMoodTopicList.push(moodTopicObj);
              setTimeout(() => {
                this.sendUnitTopicListToWebSocket([topicName])
              }, 1000);
            }
          }
        }
        else{
          if(this.unitAreaMoodTopicList && this.unitAreaMoodTopicList.length > 0){
            let responseObj = JSON.parse(JSON.stringify(response['data']))
            let topicName = responseObj['topic_name']
            let currentMoodObj = this.unitAreaMoodTopicList.find(o => o.topic_name == topicName);
            if(currentMoodObj){
              let index = this.unitAreaMoodTopicList.indexOf(currentMoodObj);
              if (index > -1) {
                currentMoodObj['payload'] =responseObj['payload']
                this.unitAreaMoodTopicList[index] = currentMoodObj;
              }
            }
          }
        }
      }
    });
  }

  hex2bin(hex){
    return ("00000000" + (parseInt(hex, 16)).toString(2)).substr(-8);
  };

  reverseString(str) {
    let myArray = str.split(',').reverse();
    return myArray.join(' ');
  };

  addLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  };

  loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  getGuestSessionDetail() {
    let response = this.guestPanelService.getHotelGuestUserSessionData()
    if (response['status'] == 1) {
      this.hotelGuestUserData = response['hotelGuestUserData']
      if(this.hotelGuestUserData && 'authorization_token' in this.hotelGuestUserData){
        this.httpOptions = { 
          'content-type': 'application/json; charset=utf-8',
          'Authorization':  'token ' + this.hotelGuestUserData['authorization_token'],
        }  
        this.loginWithFirebase();
        this.getApplianceList();
      }
    }
  };

  loginWithFirebase(){
    this.guestPanelService.getFirebaseCredentialFromServer()
      .subscribe(
        response => {
          if (response) { 
            this.afAuth.auth.signInWithEmailAndPassword(response['username'], response['password'])
            .then(result => {
              console.log(result)
            })
            .catch(error => {
              console.log(error);
            });
          }
          else if(response['status'] == 0){
            console.log(response['msg'])
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getApplianceList(){
    this.unitAreaObjectList = [];
    if('unit_id' in this.hotelGuestUserData){
      this.guestPanelService.getAppliancesList({'unit': this.hotelGuestUserData['unit_id']}, this.httpOptions)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.unitData = response;
            if('area_objects' in this.unitData && this.unitData['area_objects'].length > 0){
              this.addAppliance();
            }
            else{
              this.unitAreaObjectList = [];
            }
          }
          else if(response['status'] == 0){
            console.log(response['msg'])
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  };

  addAppliance(){
    let areaObjectList = [];
    let topicList = []
    let tempBoardHexList = []
    let tempBlasterACEquipmentTopicList = [];
    let tempAcEquipmentRefCodeList = [];
    let tempTVEquipmentRefCodeList = [];
    let tempSTVEquipmentRefCodeList = [];
    let tempAreaObjList = Array.from(this.unitData['area_objects']);
    let unitID = this.hotelGuestUserData['unit_id'];
    function addLeadingZeros(num, totalLength) {
      return String(num).padStart(totalLength, '0');
    };
    tempAreaObjList.forEach(function (currentAreaObject: object) {
      let equipmentList = [];
      let areaObj = JSON.parse(JSON.stringify(currentAreaObject));
      let areaID = addLeadingZeros(currentAreaObject['area_object_id'], 12)
      let moodTopicID = unitID + '/' + areaID +  '/' + 'MOODS';
      topicList.push(moodTopicID)
      if(currentAreaObject && 'boards' in currentAreaObject){
        currentAreaObject['boards'].forEach(function (boardObject: object) {
          if(boardObject && 'equipments' in boardObject && boardObject['equipments'].length > 0){
            if('mqtt_topic_name' in boardObject && boardObject['mqtt_topic_name']){
              let topicID = boardObject['mqtt_topic_name'] + '_1'
              topicList.push(topicID)
            }
            else{
              let areaID = addLeadingZeros(currentAreaObject['area_object_id'], 12)
              let topicID = unitID + '/' + areaID +  '/' + boardObject['board_assign_id_hex'] + '_1';
              topicList.push(topicID)
            }
            if('board_assign_id_hex' in boardObject){
              tempBoardHexList.push(boardObject['board_assign_id_hex'])
            }
            boardObject['equipments'].forEach(function (equipmentObject: object) {
              let equipmentObj = JSON.parse(JSON.stringify(equipmentObject));
              equipmentObj['type'] = 'equipment'
              equipmentList.push(equipmentObj)
            })
          }
        })
      }
      if(currentAreaObject && 'ir_blasters' in currentAreaObject){
        currentAreaObject['ir_blasters'].forEach(function (irBlasterObject: object) {
          if(irBlasterObject && 'equipments' in irBlasterObject && irBlasterObject['equipments'].length > 0){
            if('mqtt_topic_name' in irBlasterObject && irBlasterObject['mqtt_topic_name']){
              let topicID = irBlasterObject['mqtt_topic_name'];
              topicList.push(topicID)
            }
            else{
              let areaID = addLeadingZeros(currentAreaObject['area_object_id'], 12)
              let blasterIDHex = addLeadingZeros(irBlasterObject['blaster_assign_id_hex'], 12)
              let topicID = unitID + '/' + areaID +  '/' + blasterIDHex;
              topicList.push(topicID)
            }
            irBlasterObject['equipments'].forEach(function (irEquipmentObject: object) {
              let irEquipmentObj = JSON.parse(JSON.stringify(irEquipmentObject));
              irEquipmentObj['type'] = 'ir_equipment'
              equipmentList.push(irEquipmentObj)
              if(irEquipmentObject['equipment_type_id'] == 311){
                let areaID = addLeadingZeros(currentAreaObject['area_object_id'], 12)
                let equipmentID = addLeadingZeros(irEquipmentObject['equipment_id'], 12)
                let topicID = unitID + '/' + areaID +  '/' + equipmentID;
                topicList.push(topicID)
                tempBlasterACEquipmentTopicList.push(topicID)
              }
              if(irEquipmentObject['equipment_type_id'] == 311 && 
              tempAcEquipmentRefCodeList.includes(irEquipmentObject['equipment_model_reference_code']) == false){
                tempAcEquipmentRefCodeList.push(irEquipmentObject['equipment_model_reference_code'])
              }
              if(irEquipmentObject['equipment_type_id'] == 312 && 
              tempSTVEquipmentRefCodeList.includes(irEquipmentObject['equipment_model_reference_code']) == false){
                tempSTVEquipmentRefCodeList.push(irEquipmentObject['equipment_model_reference_code'])
              }
              if(irEquipmentObject['equipment_type_id'] == 313 && 
              tempTVEquipmentRefCodeList.includes(irEquipmentObject['equipment_model_reference_code']) == false){
                tempTVEquipmentRefCodeList.push(irEquipmentObject['equipment_model_reference_code'])
              }
            })
          }
        })
      }
      areaObj['equipments'] = equipmentList;
      if(equipmentList && equipmentList.length > 0){
        areaObjectList.push(areaObj);
      }
    });
    this.unitAreaObjectList = areaObjectList;
    this.boardHexList = tempBoardHexList;
    this.acEquipmentRefCodeList = tempAcEquipmentRefCodeList;
    this.tvEquipmentRefCodeList = tempTVEquipmentRefCodeList;
    this.stvEquipmentRefCodeList = tempSTVEquipmentRefCodeList;
    this.blasterACEquipmentTopicList = tempBlasterACEquipmentTopicList;
    if(topicList && topicList.length > 0){
      this.sendUnitTopicListToWebSocket(topicList);
      this.getACRmoteDataFromFirebase();
      this.getTVRmoteDataFromFirebase();
      this.getSTVRmoteDataFromFirebase();
    }
  };

  sendUnitTopicListToWebSocket(topicList){
    let data = {
      'type': 'subscribe',
      'unit_topic_list': topicList
    }
    console.log(data)
    this.webSocket.next({
       'data': data
    })
  };

  isCurrentEquipmentIsOnOffStatus(currentEquipment){
    if(currentEquipment && this.currentBoardOperationDetailList && this.currentBoardOperationDetailList.length > 0){
      let currentOperationObj = this.currentBoardOperationDetailList.find(o => o.boardHexID == currentEquipment['board_assign_id_hex']);
      if(currentOperationObj){
        if(currentEquipment['equipment_type_id'] == 13){
          let status = currentOperationObj['currentEquipmentStatusInBinary'].charAt(0)
          if(status == 1 && currentOperationObj['fanSpeedStatus'] != '00'){
            return true
          }
          return false
        }
        else{
          let status = currentOperationObj['currentEquipmentStatusInBinary'].charAt((currentOperationObj['currentEquipmentStatusInBinary'].length - 1) - currentEquipment['equipment_switch_no']);
          if(status == 1){
            return true
          }
          return false
        }
      }
      return false
    }
    return false
  };

  isCurrentACEquipmentIsOnOffStatus(currentEquipment){
    if(currentEquipment && this.currentBlasterACOperationDetailList && this.currentBlasterACOperationDetailList.length > 0){
      let unitID = this.hotelGuestUserData['unit_id'];
      let areaID = this.addLeadingZeros(currentEquipment['area_object_id'], 12)
      let equipmentID = this.addLeadingZeros(currentEquipment['equipment_id'], 12)
      let topicID = unitID + '/' + areaID +  '/' + equipmentID;
      let currentOperationObj = this.currentBlasterACOperationDetailList.find(o => o.topic_id == topicID);
      if(currentOperationObj && 'payload' in currentOperationObj){
        let currentOperationDetailObj = currentOperationObj['payload']
        if(currentOperationDetailObj && 'onOff' in currentOperationDetailObj && currentOperationDetailObj['onOff'] == 'ON'){
          return true
        }
        else{
          return false
        }
      }
      return false
    }
    return false
  }

  getFanSpeedStatus(currentEquipment){
    if(currentEquipment && this.currentBoardOperationDetailList && this.currentBoardOperationDetailList.length > 0){
      let currentOperationObj = this.currentBoardOperationDetailList.find(o => o.boardHexID == currentEquipment['board_assign_id_hex']);
      if(currentOperationObj){
        if(currentEquipment['equipment_type_id'] == 13){
          if(currentOperationObj['fanSpeedStatus']){
            return currentOperationObj['fanSpeedStatus']
          }
        }
      }
    }
  };
  

  equipmentOperationConfigInHex(currentEquipment, currentEquipmentStatusInBinary, fanSpeedStatus){
    const preFix = 'AA BB';
    const postFix = 'BB AA';
    const command = '33';
    let boardHexIDWithSpace = currentEquipment['board_assign_id_hex'].match(/.{1,2}/g).join(' ');
    let currentEquipmentStatusInHex = parseInt(currentEquipmentStatusInBinary, 2).toString(16).toUpperCase();
    if(currentEquipmentStatusInHex.length == 1){
      currentEquipmentStatusInHex = '0' +  currentEquipmentStatusInHex
    }
    let currentOperation = preFix + ' ' + command + ' ' + boardHexIDWithSpace + ' ' + currentEquipmentStatusInHex + ' ' + fanSpeedStatus + ' ' + postFix
    return currentOperation
  };

  onOffEquipment(currentEquipment){
    if(currentEquipment && this.currentBoardOperationDetailList && this.currentBoardOperationDetailList.length > 0){
      function replaceAt(str, index, ch) {
        return str.replace(/./g, (c, i) => i == index ? ch : c);
      };
      let data = {
        'type': 'publish'
      }
      if('mqtt_topic_name' in currentEquipment && currentEquipment['mqtt_topic_name']){
        data['topic'] = currentEquipment['mqtt_topic_name']
      }
      else{
        let unitID = this.hotelGuestUserData['unit_id'];
        let boardID = this.addLeadingZeros(currentEquipment['area_object_id'], 12)
        let topicID = unitID + '/' + boardID +  '/' + currentEquipment['board_assign_id_hex'];
        data['topic'] = topicID
      }
      let currentOperationObj = this.currentBoardOperationDetailList.find(o => o.boardHexID == currentEquipment['board_assign_id_hex']);
      if(currentOperationObj){
        let fanSpeedStatus = currentOperationObj['fanSpeedStatus']
        let currentEquipmentStatusInBinary = currentOperationObj['currentEquipmentStatusInBinary']
        if(currentEquipment['equipment_type_id'] == 13){
          let status = parseInt(currentEquipmentStatusInBinary.charAt(0))
          if(status == 1){
            currentEquipmentStatusInBinary = replaceAt(currentEquipmentStatusInBinary, 0, '0');
            data['request_data'] = this.equipmentOperationConfigInHex(currentEquipment, currentEquipmentStatusInBinary, fanSpeedStatus)
            currentOperationObj['currentEquipmentStatusInBinary'] = currentEquipmentStatusInBinary
          }
          else{
            currentEquipmentStatusInBinary = replaceAt(currentEquipmentStatusInBinary, 0, '1');
            data['request_data'] = this.equipmentOperationConfigInHex(currentEquipment, currentEquipmentStatusInBinary, fanSpeedStatus)
            currentOperationObj['currentEquipmentStatusInBinary'] = currentEquipmentStatusInBinary
            currentOperationObj['fanSpeedStatus'] = fanSpeedStatus
          }
        }
        else{
          let postion = (currentEquipmentStatusInBinary.length - 1) - currentEquipment['equipment_switch_no']
          let status =  parseInt(currentEquipmentStatusInBinary.charAt(postion));
          if (status == 1){
            currentEquipmentStatusInBinary = replaceAt(currentEquipmentStatusInBinary, postion, '0');
            data['request_data'] = this.equipmentOperationConfigInHex(currentEquipment, currentEquipmentStatusInBinary, fanSpeedStatus)
            currentOperationObj['currentEquipmentStatusInBinary'] = currentEquipmentStatusInBinary
          }
          else{
            currentEquipmentStatusInBinary = replaceAt(currentEquipmentStatusInBinary, postion, '1');
            data['request_data'] = this.equipmentOperationConfigInHex(currentEquipment, currentEquipmentStatusInBinary, fanSpeedStatus)
            currentOperationObj['currentEquipmentStatusInBinary'] = currentEquipmentStatusInBinary
          }
        }
      }
      else{
        let fanSpeedStatus = '00'
        let currentEquipmentStatusInBinary = '00000000'
        let operationDetailNewObj = {
          'boardHexID': currentEquipment['board_assign_id_hex']
        }
        if(currentEquipment['equipment_type_id'] == 13){
          let status = parseInt(currentEquipmentStatusInBinary.charAt(0))
          if(status == 1){
            currentEquipmentStatusInBinary = replaceAt(currentEquipmentStatusInBinary, 0, '0');
            data['request_data'] = this.equipmentOperationConfigInHex(currentEquipment, currentEquipmentStatusInBinary, fanSpeedStatus)
            operationDetailNewObj['currentEquipmentStatusInBinary'] = currentEquipmentStatusInBinary
            operationDetailNewObj['fanSpeedStatus'] = fanSpeedStatus
            this.currentBoardOperationDetailList.push(operationDetailNewObj);
          }
          else{
            currentEquipmentStatusInBinary = replaceAt(currentEquipmentStatusInBinary, 0, '1');
            data['request_data'] = this.equipmentOperationConfigInHex(currentEquipment, currentEquipmentStatusInBinary, fanSpeedStatus)
            operationDetailNewObj['currentEquipmentStatusInBinary'] = currentEquipmentStatusInBinary
            operationDetailNewObj['fanSpeedStatus'] = fanSpeedStatus
            this.currentBoardOperationDetailList.push(operationDetailNewObj);
          }
        }
        else{
          let postion = (currentEquipmentStatusInBinary.length - 1) - currentEquipment['equipment_switch_no']
          let status = parseInt(currentEquipmentStatusInBinary.charAt(postion));
          if (status == 1){
            currentEquipmentStatusInBinary = replaceAt(currentEquipmentStatusInBinary, postion, '0');
            data['request_data'] = this.equipmentOperationConfigInHex(currentEquipment, currentEquipmentStatusInBinary, fanSpeedStatus)
            operationDetailNewObj['currentEquipmentStatusInBinary'] = currentEquipmentStatusInBinary
            operationDetailNewObj['fanSpeedStatus'] = fanSpeedStatus
            this.currentBoardOperationDetailList.push(operationDetailNewObj);
          }
          else{
            currentEquipmentStatusInBinary = replaceAt(currentEquipmentStatusInBinary, postion, '1');
            data['request_data'] = this.equipmentOperationConfigInHex(currentEquipment, currentEquipmentStatusInBinary, fanSpeedStatus)
            operationDetailNewObj['currentEquipmentStatusInBinary'] = currentEquipmentStatusInBinary
            operationDetailNewObj['fanSpeedStatus'] = fanSpeedStatus
            this.currentBoardOperationDetailList.push(operationDetailNewObj);
          }
        }
      }
      console.log(data)
      this.webSocket.next({'data': data});
    }
  };

  currentSelectedFanEquipment(currentEquipment){
    this.currentFanEquipment = {}
    if(currentEquipment){
      this.currentFanEquipment = currentEquipment;
    }
  };

  onOffFanEquipment(currentFanSpeed){
    if(this.currentFanEquipment){
      let currentOperationObj = this.currentBoardOperationDetailList.find(o => o.boardHexID == this.currentFanEquipment['board_assign_id_hex']);
      if(currentOperationObj){
        if(this.currentFanEquipment['equipment_type_id'] == 13){
          if(currentFanSpeed != ''){
            currentOperationObj['fanSpeedStatus'] = currentFanSpeed
          }
          this.onOffEquipment(this.currentFanEquipment)
        }
      }
    }
  };

  getACRmoteDataFromFirebase(){ 
    let tempACEquipmentRefCodeList = Array.from(this.acEquipmentRefCodeList);
    function getAcRemoteData(acRefCode, thisRef){
      let RemoteRef = thisRef.db
      .object('Remote/' + '/Air Conditioner/'  + acRefCode)
      .valueChanges();
      thisRef.subscription = RemoteRef.subscribe(response => {
        let remoteDataList = [] 
        let dataObj = Object.assign({}, response);
        Object.keys(dataObj).forEach(function (key) {
          remoteDataList.push(dataObj[key])
        })
        let acRemoteDataObj = {
          'reference_code': acRefCode,
          'remoteDataList':remoteDataList
        }
        thisRef.acEquipmentRefCodeRemoteDataList.push(acRemoteDataObj);
        let index = tempACEquipmentRefCodeList.indexOf(acRefCode);
        if (index > -1) {
          tempACEquipmentRefCodeList.splice(index, 1);
        }
        if (tempACEquipmentRefCodeList && tempACEquipmentRefCodeList.length > 0) {
          getAcRemoteData(tempACEquipmentRefCodeList[0], thisRef);
        }
        // else {
        //   console.log(thisRef.acEquipmentRefCodeRemoteDataList)
        // }
      });
    }
    if (tempACEquipmentRefCodeList && tempACEquipmentRefCodeList.length > 0) {
      getAcRemoteData(tempACEquipmentRefCodeList[0], this)
    }
  };

  getTVRmoteDataFromFirebase(){ 
    let tempTVEquipmentRefCodeList = Array.from(this.tvEquipmentRefCodeList);
    function getTVRemoteData(tvRefCode, thisRef){
      let RemoteRef = thisRef.db
      .object('Remote/' + '/Television/'  + tvRefCode)
      .valueChanges();
      thisRef.subscription = RemoteRef.subscribe(response => {
        let remoteDataList = [] 
        let dataObj = Object.assign({}, response);
        Object.keys(dataObj).forEach(function (key) {
          remoteDataList.push(dataObj[key])
        })
        let tvRemoteDataObj = {
          'reference_code': tvRefCode,
          'remoteDataList':remoteDataList
        }
        thisRef.tvEquipmentRefCodeRemoteDataList.push(tvRemoteDataObj);
        let index = tempTVEquipmentRefCodeList.indexOf(tvRefCode);
        if (index > -1) {
          tempTVEquipmentRefCodeList.splice(index, 1);
        }
        if (tempTVEquipmentRefCodeList && tempTVEquipmentRefCodeList.length > 0) {
          getTVRemoteData(tempTVEquipmentRefCodeList[0], thisRef);
        }
        // else {
        //   console.log(thisRef.tvEquipmentRefCodeRemoteDataList)
        // }
      });
    }
    if (tempTVEquipmentRefCodeList && tempTVEquipmentRefCodeList.length > 0) {
      getTVRemoteData(tempTVEquipmentRefCodeList[0], this)
    }
  };

  getSTVRmoteDataFromFirebase(){ 
    let tempSTVEquipmentRefCodeList = Array.from(this.stvEquipmentRefCodeList);
    function getSTVRemoteData(stvRefCode, thisRef){
      let RemoteRef = thisRef.db
      .object('Remote/' + '/Set - Top Box/'  + stvRefCode)
      .valueChanges();
      thisRef.subscription = RemoteRef.subscribe(response => {
        let remoteDataList = [] 
        let dataObj = Object.assign({}, response);
        Object.keys(dataObj).forEach(function (key) {
          remoteDataList.push(dataObj[key])
        })
        let stvRemoteDataObj = {
          'reference_code': stvRefCode,
          'remoteDataList':remoteDataList
        }
        thisRef.stvEquipmentRefCodeRemoteDataList.push(stvRemoteDataObj);
        let index = tempSTVEquipmentRefCodeList.indexOf(stvRefCode);
        if (index > -1) {
          tempSTVEquipmentRefCodeList.splice(index, 1);
        }
        if (tempSTVEquipmentRefCodeList && tempSTVEquipmentRefCodeList.length > 0) {
          getSTVRemoteData(tempSTVEquipmentRefCodeList[0], thisRef);
        }
        else {
          // console.log(thisRef.stvEquipmentRefCodeRemoteDataList)
          thisRef.getSTVChannelListFromFirebase();
        }
      });
    }
    if (tempSTVEquipmentRefCodeList && tempSTVEquipmentRefCodeList.length > 0) {
      getSTVRemoteData(tempSTVEquipmentRefCodeList[0], this)
    }
  };

  getSTVChannelListFromFirebase(){ 
    let channelRef = this.db
      .object('Remote/' + '/ChannelSheet/' + 'RefChannels')
      .valueChanges();
    this.subscription = channelRef.subscribe(response => {
      let channelDataList = [] 
      let dataObj = Object.assign({}, response);
      Object.keys(dataObj).forEach(function (key) {
        let channelObj = {
          'channel_name': key,
          'data': dataObj[key]
        }
        channelDataList.push(channelObj)
      })
      this.stvChannelDataList = channelDataList;
    })
  };

  isBinarySwingMatch(currentBimary, currentBinaryStagesSwing){
    if(currentBimary && currentBinaryStagesSwing){
       if(currentBimary.toLowerCase() == currentBinaryStagesSwing.toLowerCase()){
          return true
       }
       else{
        return false
       }
    }
    return false
  };

  currentSelectedACEquipment(currentACEquipment){
    console.log(currentACEquipment)
    this.currentACEquipment = {}
    this.currentBinaryStagesSwingList = [];
    this.currentACModeList = [];
    this.currentACTemputure = null;
    this.currentSelectedACMode = '';
    this.currentACBinarySwing = '';
    this.currentSelectedACOnOffStatus = 'OFF'
    this.currentACEquipment = currentACEquipment;
    if(currentACEquipment && this.currentBlasterACOperationDetailList && this.currentBlasterACOperationDetailList.length > 0){
      if ('modes' in this.currentACEquipment){
        this.currentACModeList = this.currentACEquipment['modes'].split(',')
      }
      if ('binary_stages_swing' in this.currentACEquipment){
        this.currentBinaryStagesSwingList = this.currentACEquipment['binary_stages_swing'].split(',')
      }
      let unitID = this.hotelGuestUserData['unit_id'];
      let areaID = this.addLeadingZeros(currentACEquipment['area_object_id'], 12)
      let equipmentID = this.addLeadingZeros(currentACEquipment['equipment_id'], 12)
      let topicID = unitID + '/' + areaID +  '/' + equipmentID;
      let currentOperationObj = this.currentBlasterACOperationDetailList.find(o => o.topic_id == topicID);
      if(currentOperationObj && 'payload' in currentOperationObj){
        let currentOperationDetailObj = currentOperationObj['payload']
        if('onOff' in currentOperationDetailObj){
          this.currentSelectedACOnOffStatus = currentOperationDetailObj['onOff']
        }
        if('mode' in currentOperationDetailObj){
          this.currentSelectedACMode = currentOperationDetailObj['mode']
        }
        if('swing' in currentOperationDetailObj){
          this.currentACBinarySwing = currentOperationDetailObj['swing']
        }
        if('temperature' in currentOperationDetailObj){
          if(Number(currentOperationDetailObj['temperature']) == 0){
            this.currentACTemputure = this.currentACEquipment['minimum_temperature']
          }
          else{
            this.currentACTemputure = Number(currentOperationDetailObj['temperature'])
          }
        }
      }
      else{
        this.currentACTemputure = this.currentACEquipment['minimum_temperature'];
        this.currentACBinarySwing = 'SWING';
        this.currentSelectedACMode = 'AUTO';
      }
    }
    else{
      this.currentACTemputure = this.currentACEquipment['minimum_temperature'];
      this.currentACBinarySwing = 'SWING';
      this.currentSelectedACMode = 'AUTO';
    }
  };

  operateACEquipment(buttonActivityData){
    if(buttonActivityData['type'] == 'mode'){
      this.currentSelectedACMode = buttonActivityData['data']
    }
    else if(buttonActivityData['type'] == 'banary_stages'){
      this.currentACBinarySwing = buttonActivityData['data']
    }
    else if(this.currentACTemputure > this.currentACEquipment['minimum_temperature'] && buttonActivityData['type'] == 'deincrease'){
      this.currentACTemputure = this.currentACTemputure - 1;
    }
    else if(this.currentACTemputure < this.currentACEquipment['maximum_temperature'] && buttonActivityData['type'] == 'increase'){
      this.currentACTemputure = this.currentACTemputure + 1;
    }
    else if(buttonActivityData['type'] == 'on-off'){
      if(this.currentSelectedACOnOffStatus == 'ON'){
        this.currentSelectedACOnOffStatus = 'OFF';
      }
      else{
        this.currentSelectedACOnOffStatus = 'ON';
      }
    }
    let acEquipmentRefCodeRemoteObj = this.acEquipmentRefCodeRemoteDataList.find(
      o => o.reference_code == this.currentACEquipment['equipment_model_reference_code']);
    let remoteDataList = acEquipmentRefCodeRemoteObj['remoteDataList']
    console.log(remoteDataList)
    let acRemoteObj;
    if(this.currentSelectedACOnOffStatus == 'ON'){
      acRemoteObj = remoteDataList.find(o => 
        o.temperature == this.currentACTemputure &&
        o.onOff == this.currentSelectedACOnOffStatus &&
        o.mode == this.currentSelectedACMode
      );
    }
    else{
      acRemoteObj = remoteDataList.find(o => 
        o.temperature == 0 &&
        o.onOff == this.currentSelectedACOnOffStatus
      );
    }
    console.log(acRemoteObj)
    if(acRemoteObj){
      const preFix = '7E 00 22 10 01 00 00 00 00 00 00 FF FF FF FE 00 00 AE AF';
      const postFix = 'B5 B6 3F';
      const command = '51';
      let blasterHexIDWithSpace = this.currentACEquipment['blaster_assign_id_hex'].match(/.{1,2}/g).join(' ');
      const acRemoteHexCodeWithSpace = acRemoteObj['hexCode'].match(/.{1,2}/g).join(' ');
      const acRemoteHexCode = this.reverseString(acRemoteHexCodeWithSpace)
      let currentOperation = preFix + ' ' + blasterHexIDWithSpace + ' ' + command + ' ' + acRemoteHexCode + ' ' + postFix;
      let data = {
        'type': 'publish',
        'topic': this.currentACEquipment['mqtt_topic_name'],
        'request_data': currentOperation
      }
      console.log(data)
      this.webSocket.next({'data': data});
      let unitID = this.hotelGuestUserData['unit_id'];
      let areaID = this.addLeadingZeros(this.currentACEquipment['area_object_id'], 12)
      let equipmentID = this.addLeadingZeros(this.currentACEquipment['equipment_id'], 12)
      let topicID = unitID + '/' + areaID +  '/' + equipmentID;
      let currentOperationObj = this.currentBlasterACOperationDetailList.find(o => o.topic_id == topicID);
      if(currentOperationObj && 'payload' in currentOperationObj){
        let payload = currentOperationObj['payload']
        payload['onOff'] = this.currentSelectedACOnOffStatus
        payload['mode'] = this.currentSelectedACMode
        payload['swing'] = this.currentACBinarySwing
        payload['temperature'] = String(this.currentACTemputure)
        let data = {
          'type': 'publish',
          'topic': topicID,
          'request_data': JSON.stringify(currentOperationObj['payload'])
        }
        console.log(data)
        this.webSocket.next({'data': data});
      }
      else{
        let currentOperationObj = {
          'topic_id': topicID,
          'payload': {
            'mode': this.currentSelectedACMode,
            'temperature': String(this.currentACTemputure),
            'onOff': this.currentSelectedACOnOffStatus,
            'swing': this.currentACBinarySwing,
            'DeviceID': ''
          }
        }
        let data = {
          'type': 'publish',
          'topic': topicID,
          'request_data': JSON.stringify(currentOperationObj['payload'])
        }
        this.webSocket.next({'data': data});
        this.currentBlasterACOperationDetailList.push(currentOperationObj)
      }
    }
  };

  currentSelectedTVEquipment(currentTVEquipment){
    console.log(currentTVEquipment)
    this.currentTVEquipment = {}
    if(currentTVEquipment){
      this.currentTVEquipment = currentTVEquipment;
    }
  };

  operateTVEquipment(buttonActivityData){
    if(buttonActivityData){
      let tvEquipmentRefCodeRemoteObj = this.tvEquipmentRefCodeRemoteDataList.find(
        o => o.reference_code == this.currentTVEquipment['equipment_model_reference_code']);
      let remoteDataList = tvEquipmentRefCodeRemoteObj['remoteDataList']
      let tvRemoteObj = remoteDataList.find(o => 
        o.operation == buttonActivityData
      );
      if(tvRemoteObj){
        const preFix = '7E 00 22 10 01 00 00 00 00 00 00 FF FF FF FE 00 00 AE AF';
        const postFix = 'B5 B6 3F';
        const command = '51';
        let blasterHexIDWithSpace = this.currentTVEquipment['blaster_assign_id_hex'].match(/.{1,2}/g).join(' ');
        const tvRemoteHexCodeWithSpace = tvRemoteObj['hexCode'].match(/.{1,2}/g).join(' ');
        const tvRemoteHexCode = this.reverseString(tvRemoteHexCodeWithSpace)
        let currentOperation = preFix + ' ' + blasterHexIDWithSpace + ' ' + command + ' ' + tvRemoteHexCode + ' ' + postFix;
        let data = {
          'type': 'publish',
          'topic': this.currentTVEquipment['mqtt_topic_name'],
          'request_data': currentOperation
        }
        console.log(data)
        this.webSocket.next({'data': data});
      }
    }
  };

  currentSelectedSTVEquipment(currentSTVEquipment){
    console.log(currentSTVEquipment)
    this.currentSTVEquipment = {}
    if(currentSTVEquipment){
      this.currentSTVEquipment = currentSTVEquipment;
    }
  };

  operateSTVEquipment(buttonActivityData){
    if(buttonActivityData && this.currentSTVEquipment){
      let stvEquipmentRefCodeRemoteObj = this.stvEquipmentRefCodeRemoteDataList.find(
        o => o.reference_code == this.currentSTVEquipment['equipment_model_reference_code']);
      let remoteDataList = stvEquipmentRefCodeRemoteObj['remoteDataList']
      let stvRemoteObj = remoteDataList.find(o => 
        o.operation == buttonActivityData
      );
      if(stvRemoteObj){
        const preFix = '7E 00 22 10 01 00 00 00 00 00 00 FF FF FF FE 00 00 AE AF';
        const postFix = 'B5 B6 3F';
        const command = '51';
        let blasterHexIDWithSpace = this.currentSTVEquipment['blaster_assign_id_hex'].match(/.{1,2}/g).join(' ');
        const stvRemoteHexCodeWithSpace = stvRemoteObj['hexCode'].match(/.{1,2}/g).join(' ');
        const stvRemoteHexCode = this.reverseString(stvRemoteHexCodeWithSpace)
        let currentOperation = preFix + ' ' + blasterHexIDWithSpace + ' ' + command + ' ' + stvRemoteHexCode + ' ' + postFix;
        let data = {
          'type': 'publish',
          'topic': this.currentSTVEquipment['mqtt_topic_name'],
          'request_data': currentOperation
        }
        console.log(data)
        this.webSocket.next({'data': data});
      }
    }
  };

  operateSTVChannel(currentChannel){
    if(currentChannel && this.currentSTVEquipment){
      if('equipment_model_brand_name' in this.currentSTVEquipment && 'data' in currentChannel){
        let brandName =  this.currentSTVEquipment['equipment_model_brand_name']
        let channelProvider = JSON.parse(currentChannel['data']['channel_number_by_provider'])
        if(brandName && channelProvider && channelProvider[brandName]){
          let channelNumber = String(channelProvider[brandName])
          let channelNumberArray = channelNumber.split("");
          console.log(channelNumberArray)
          if(channelNumberArray && channelNumberArray.length > 0){
            for (let val of channelNumberArray) {
              this.operateSTVEquipment(val)
            }
          }
        }
      }
    }
  };

  operateMoode(currentMood){
    if(currentMood && 'topic_name' in currentMood && 'payload' in currentMood){
      if(currentMood['payload'] != ''){
        let currentMoodCommandList = currentMood['payload'].split(',');
        console.log(currentMoodCommandList)
        for (let currentOperation of currentMoodCommandList) {
          let data = {
            'type': 'publish',
            'topic': currentMood['topic_name'],
            'request_data': currentOperation
          }
          console.log(data)
          this.webSocket.next({'data': data});
        }
      }

    }
  };

  ngOnDestroy(){
    this.webSocket.unsubscribe()
    this.webSocket.next({'data': {'type': 'disconnect'}});
  };
  

}
