import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { StaffFrontDeskPanelAuthService } from '../staff-front-desk-panel-auth.service';
import { StaffFrontDeskPanelService } from '../staff-front-desk-panel.service';


@Injectable({
  providedIn: 'root'
})
export class StaffLoginGuardService implements  CanActivate{

  constructor(
    public auth: StaffFrontDeskPanelAuthService, 
    public staffFrontDeskPanelService: StaffFrontDeskPanelService,
    public router: Router) {}

  canActivate() {
    if(this.auth.isStaffUserLogin()) {
      let response = this.staffFrontDeskPanelService.getHotelStaffUserSessionData()
      if(response['status'] == 1){
        let hotelStaffUserData = response['hotelStaffUserData']
        if(hotelStaffUserData && 'hotel_department_name' in hotelStaffUserData && 
          hotelStaffUserData['hotel_department_name'] == 'Food & Beverages'){
            this.router.navigate(['/front-desk/guest-meal-order-request'])
            return false;
        }
        this.router.navigate(['/front-desk/guest-panel'])
        return false;
      }
    }
    else {
      return true;
    }
  };
}
