import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  Inject,
  ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { HotelAdminPanelService } from './../hotel-admin-panel.service';

@Component({
  selector: 'app-hotel-admin-change-logo',
  templateUrl: './hotel-admin-change-logo.component.html',
  styleUrls: ['./hotel-admin-change-logo.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HotelAdminChangeLogoComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({});
  hotelUserData = {};
  errorObj = {};
  hotolLogo: any;
  ishotolLogoUpload:boolean = false;
  currentActiveRoute: string

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private toastr: ToastrService,
    private hotelAdminPanelService: HotelAdminPanelService
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/admin/design-common.js");
    this.currentActiveRoute = this.router.url;
    this.getHotelSessionDetail();
  }

  public loadJsFile(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('body')[0].appendChild(node);  
  }  

  getHotelSessionDetail(){
    let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
    if(response['status'] == 1){
      this.hotelUserData = response['hotelUserData']
    }
  };

  uploadHotelLogo(event: EventEmitter<File[]>) {
    this.ishotolLogoUpload = false;
    const hotolLogoFile: File = event[0];
    this.hotolLogo = hotolLogoFile
    var reader = new FileReader();
    reader.readAsDataURL(hotolLogoFile);
    reader.onload = (_event) => {
      this.hotelUserData['hotel_logo'] = reader.result;
      this.ishotolLogoUpload = true;
    }
  };

  changeHotelLogo(){
    let formData: FormData = new FormData();
    formData.append('hotel_id', this.hotelUserData['hotel_id'])
    formData.append('logo', this.hotolLogo)
    this.hotelAdminPanelService.changeHotelLogo(formData)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.hotelUserData['hotel_logo'] = response['data']['logo'];
            this.ishotolLogoUpload = false;
            this.toastr.success('Hotel logo changed successfully')
            this.hotelAdminPanelService.storeHotelAdminfUserSessionData(this.hotelUserData, true)
            window.location.reload();
          }
          else{
            if(response['msg'] && response['status'] == 0){
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              this.toastr.error(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );

  }

}
