import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  ViewEncapsulation
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router"
import { HotelAdminPanelService } from '../hotel-admin-panel.service';

@Component({
  selector: 'app-miscellaneous-setting',
  templateUrl: './miscellaneous-setting.component.html',
  styleUrls: ['./miscellaneous-setting.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MiscellaneousSettingComponent implements OnInit {
  errorMessage: string = '';
  errorObj = {};
  currentHotelAdminUserData = {};
  hotelRoomServiceList = [];
  hotelSiteHelplineObj = {};
  currentSelectionTab: string = 'taxSetting'
  constructor(
    private toastr: ToastrService,
    private hotelAdminPanelService: HotelAdminPanelService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/admin/design-common.js");
    this.getHotelAdminUserSessionDetail();
  }

  public loadJsFile(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('body')[0].appendChild(node);  
  }

  getHotelAdminUserSessionDetail(){
    let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
    if(response['status'] == 1){
      this.currentHotelAdminUserData = response['hotelUserData']
      this.getHotelRoomServiceList();
      this.getHotelSiteHelplineNumber();
    }
  };

  getHotelRoomServiceList(){
    this.hotelRoomServiceList = [];
    this.hotelAdminPanelService.getHotelRoomServiceList({'hotel_id': this.currentHotelAdminUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            let tempHotelRoomServiceList = response['data']
            if(tempHotelRoomServiceList && tempHotelRoomServiceList.length > 0){
              for(let i=0; i<tempHotelRoomServiceList.length;i++){
                if(tempHotelRoomServiceList[i]['is_chargeable'] == 'Yes'){
                  this.hotelRoomServiceList.push(tempHotelRoomServiceList[i])
                }
              }
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelSiteHelplineNumber(){
    this.hotelSiteHelplineObj = {};
    this.hotelAdminPanelService.getHotelSiteHelplineNumber({'hotel_id': this.currentHotelAdminUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.hotelSiteHelplineObj = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  updateRoomServiceTaxSetail(currentRoomService){
    let data = {}
    if(currentRoomService && 'id' in currentRoomService && 'tax' in currentRoomService && currentRoomService['tax'] != '' ){
      data['room_service_id'] = currentRoomService['id']
      data['tax'] = currentRoomService['tax']
      this.hotelAdminPanelService.updateHotelRoomServiceTaxDetail(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.getHotelRoomServiceList();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    }

  };

  updateHotelSiteHelpLineNumberDetail(currentType){
    let data = {}
    if(currentType){
      data['hotel_site_helpline_number_id'] = this.hotelSiteHelplineObj['id']
      if(currentType == 'Fire_Safety'){
        data['fire_safety'] = this.hotelSiteHelplineObj['fire_safety']
      }
      if(currentType == 'Medical_Emergency'){
        data['medical'] = this.hotelSiteHelplineObj['medical']
      }
      if(currentType == 'Front_Desk'){
        data['front_desk'] = this.hotelSiteHelplineObj['front_desk']
      }
      if(currentType == 'Emergency_Helpline'){
        data['emergency'] = this.hotelSiteHelplineObj['emergency']
      }
      this.hotelAdminPanelService.updateHotelSiteHelplineNumber(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.getHotelSiteHelplineNumber();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
    }

  };

}
