import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import { GuestPanelService } from '../../guest-panel.service';

@Component({
  selector: 'app-guest-panel-bottom-navigation',
  templateUrl: './guest-panel-bottom-navigation.component.html',
  styleUrls: ['./guest-panel-bottom-navigation.component.css']
})
export class GuestPanelBottomNavigationComponent implements OnInit {
  currentActiveRoute;
  hotelGuestUserData;
  isFoodBeverageEnabled: boolean = false;
  constructor(
    private router: Router,
    private guestPanelService: GuestPanelService
  ) { }

  ngOnInit() {
    this.getGuestSessionDetail();
    this.currentActiveRoute = this.router.url;
  }

  getGuestSessionDetail(){
    let response = this.guestPanelService.getHotelGuestUserSessionData()
    if(response['status'] == 1){
      this.hotelGuestUserData = response['hotelGuestUserData']
      this.isGuestFoodBeverageEnableService();
    }
  };

  isGuestFoodBeverageEnableService(){
    this.guestPanelService.isFoodBeverageServiceEnable({'hotel_id': this.hotelGuestUserData['guest_detail']['hotel_id']})
    .subscribe(
      response => {
        if(response['is_enable'] == true){
          this.isFoodBeverageEnabled = true;
        }
        else{
          this.isFoodBeverageEnabled = false;
        }
      },
      error => {
        console.log(error);
      }
    );
  };

}
