import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { HotelAdminPanelAuthService } from './hotel-admin-panel-auth.service';
import { HotelAdminPanelService } from './hotel-admin-panel.service';

@Injectable({
  providedIn: 'root'
})
export class HotelAdminPanelAuthGuardService implements CanActivate {

  constructor(
    public auth: HotelAdminPanelAuthService, 
    public router: Router,
    public hotelAdminPanelService: HotelAdminPanelService
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if(!this.auth.isAuthenticated()) {
      this.router.navigate(['/login'])
      return false;
    }
    else {
      let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
      if(response['status'] == 1){
        let hotelUserData = response['hotelUserData']
        return new Promise(res => {
          this.hotelAdminPanelService.validateCurrentHotelAdminDetail(
            {'hotel_authority_id': hotelUserData['id']})
            .subscribe(
              (result) => {
                  if(result['status'] == 1) {
                      res(true);
                  } else {
                      this.hotelAdminPanelService.clearHotelAdminUserSessionData();
                      this.router.navigate(['/login'])
                      res(false);
                  }
              },
          );
        });
      }
    }
  };

}
