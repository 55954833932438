import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HotelAdminPanelService {

  constructor(
    private httpClient: HttpClient
  ) { }

  // store hotel session data
  storeHotelAdminfUserSessionData(data, isSignIn){
    data['scope'] = 'hotelAdminUser'
    localStorage.setItem('hotelUserData', JSON.stringify(data))
    if(isSignIn){
      localStorage.setItem('isSignIn', JSON.stringify(isSignIn))
    }
    return({
      'status': 1,
      'msg': 'hotel admin user session details successfully set.'
    });
  };

  // get hotel admin user session data
  getHotelAdminUserSessionData(){
    let hotelUserData = JSON.parse(localStorage.getItem('hotelUserData'));
    let isSignIn = JSON.parse(localStorage.getItem('isSignIn'));
    return({
      'status': 1,
      'hotelUserData': hotelUserData,
      'isSignIn': isSignIn,
      'msg': 'hotel admin user session details successfully get.'
    });
  };

  // clear hotel staff user session data
  clearHotelAdminUserSessionData(){
    localStorage.removeItem('isSignIn')
    localStorage.removeItem('hotelUserData')
    return({
      'status': 1,
      'msg': 'hotel admin user session details successfully clear.'
    });
  };

  // get hotel staff user detail
  getHotelAdminUserDetail(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-hotel-authority-user-detail/',
        data
    );
  };

  getErrorResponse(message){
    if(message){
      const result = message.split(':');
      if(result && result.length == 2){
        let objKey = result[0];
        let objValue = result[1];
        let errorObj = {}
        errorObj[objKey] = objValue
        return errorObj
      }
      return {}
    }
    return {}
   
  };

  validateCurrentHotelAdminDetail(data){
    return this.httpClient.post(
      environment.apiBaseURL + '/api/hotel/validate-hotel-authority-user-details/',
      data
    );
  }

  changeHotelLogo(data){
    return this.httpClient.post(
      environment.apiBaseURL + '/api/hotel/change-hotel-logo/',
      data
    );
  };

  changeHotelAdminPassword(data){
    return this.httpClient.post(
      environment.apiBaseURL + '/api/hotel/change-password/',
      data
    );
  };

  getGlobalRoomTypeList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/get-global-room-type-list/',
        data
    );
  };

  getRoomTypeListByHotel(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-room-type-list-by-hotel/',
        data
    );
  };

  createHotelRoomType(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/create-hotel-room-type/',
        data
    );
  };

  removeHotelRoomType(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/remove-hotel-room-type/',
        data
    );
  };

  getWingListByHotel(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-wing-list-by-hotel/',
        data
    );
  };

  createHotelWing(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/create-hotel-wing/',
        data
    );
  };

  updateHotelWing(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/change-hotel-wing-detail/',
        data
    );
  };

  removeHotelWing(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/remove-hotel-wing/',
        data
    );
  };

  getHotelWingRoomList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-room-list-by-wing/',
        data
    );
  };

  createHotelRoom(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/create-hotel-room/',
        data
    );
  };

  updateHotelRoom(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/change-hotel-room-detail/',
        data
    );
  };

  removeHotelRoom(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/remove-hotel-room/',
        data
    );
  };

  getGlobalUnitOfMeasureList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/get-global-unit-of-measures-list/',
        data
    );
  };

  getGlobalRoomServiceList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/get-global-room-service-list/',
        data
    );
  };

  getHotelDepartmentList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-department-list-by-hotel/',
        data
    );
  };

  getHotelStaffRoleList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-staff-role-list-by-hotel/',
        data
    );
  };

  getHotelRoomServiceList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-room-service-list-by-hotel/',
        data
    );
  };

  createHotelRoomService(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/create-hotel-room-service/',
        data
    );
  };

  updateHotelRoomService(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/change-hotel-room-service-detail/',
        data
    );
  };

  removeHotelRoomService(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/remove-hotel-room-service/',
        data
    );
  };

  getCountryList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/get-country/',
        data
    );
  };

  getStateListByCountry(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/get-state/',
        data
    );
  };

  getCityListByState(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/get-city/',
        data
    );
  };

  getHotelStaffList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-staff-list-by-hotel/',
        data
    );
  };

  createHotelStaff(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/create-hotel-staff/',
        data
    );
  };

  updateHotelStaff(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/change-hotel-staff-detail/',
        data
    );
  };

  getFoodMenuItemList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/get-food-menu-item-time-list/',
        data
    );
  };

  getFoodMenuCategoryList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/get-food-menu-item-category-list/',
        data
    );
  };

  getFoodMenuItemTimeList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/get-food-menu-item-time-list/',
        data
    );
  };

  getHotelFoodItemList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-food-item-list-by-hotel/',
        data
    );
  };

  uploadMediaFile(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/upload-media/',
        data
    );
  };

  createHotelFoodItem(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/create-hotel-food-item/',
        data
    );
  };

  updateHotelFoodItem(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/change-hotel-food-item-detail/',
        data
    );
  };

  removeHotelFoodItem(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/remove-hotel-food-item/',
        data
    );
  };

  createHotelDepartment(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/create-hotel-department/',
        data
    );
  };

  updateHotelDepartment(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/change-hotel-department-detail/',
        data
    );
  };

  removeHotelDepartment(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/remove-hotel-department/',
        data
    );
  };

  createHotelStaffRole(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/create-hotel-staff-role/',
        data
    );
  };

  updateHotelStaffRole(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/change-hotel-staff-role-detail/',
        data
    );
  };

  removeHotelStaffRole(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/remove-hotel-staff-role/',
        data
    );
  };

  assignDepartmentAndStaffRoleToRoomService(data){
    return this.httpClient.post(
      environment.apiBaseURL + '/api/hotel/assign-department-staff-role-to-room-service/',
      data
    );
  };

  createHotelStaffAssignment(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/create-hotel-staff-assignment/',
        data
    );
  };

  endStaffAssignment(data){
    return this.httpClient.post(
      environment.apiBaseURL + '/api/hotel/end-hotel-staff-assignment/',
      data
    );
  };

  getHotelFNBOutletList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-fnb-outlet-list-by-hotel/',
        data
    );
  };

  createHotelFNBOutlet(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/create-fnb-outlet/',
        data
    );
  };

  getHotelSiteHelplineNumber(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-helpline-number-by-hotel/',
        data
    );
  };

  updateHotelSiteHelplineNumber(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/update-hotel-site-helpline-number/',
        data
    );
  };

  updateHotelRoomServiceTaxDetail(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/change-hotel-room-service-tax-detail/',
        data
    );
  };

  getHotelFNBOutletTableList(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-fnb-outlet-table-list-by-outlet/',
        data
    );
  };

  createHotelFNBOutletTable(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/create-hotel-fnb-outlet-table/',
        data
    );
  };

  updateHotelFNBOutletTable(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/update-hotel-fnb-outlet-table/',
        data
    );
  };

  removeHotelFNBOutletTable(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/remove-hotel-fnb-outlet-table/',
        data
    );
  };

  updateHotelFNBOutlet(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/update-fnb-outlet-detail/',
        data
    );
  };

  removeHotelFNBOutlet(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/remove-fnb-outlet/',
        data
    );
  };

  validateHotelStaffCellNumber(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/validate-hotel-staff-cell-number/',
        data
    );
  };

  validateHotelStaffEmail(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/validate-hotel-staff-email/',
        data
    );
  };

  validateHotelStaffEmployeeCode(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/validate-hotel-staff-employee-code/',
        data
    );
  };

  forgotPasswordGenerateOTP(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/forgot-password-generate-otp/',
        data
    );
  };

  forgotPasswordVerifyOTP(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/forgot-password-verify-otp/',
        data
    );
  };

  assignDepartmentAndStaffRoleToFNBOutlet(data){
    return this.httpClient.post(
      environment.apiBaseURL + '/api/hotel/assign-department-staff-role-to-fnb-outlet/',
      data
    );
  };

  getHotelAvailabilityData(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/get-hotel-availability-detail/',
        data
    );
  };

  updateHotelAvailabilityData(data){
    return this.httpClient.post(
        environment.apiBaseURL + '/api/hotel/change-hotel-availability-detail/',
        data
    );
  };

}
