import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Router} from "@angular/router"
import { StaffFrontDeskPanelService } from '../staff-front-desk-panel.service';

@Component({
  selector: 'app-staff-detail',
  templateUrl: './staff-detail.component.html',
  styleUrls: ['./staff-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StaffDetailComponent implements OnInit {

  hotelStaffUserData = {};
  currentActiveRoute: string

  constructor(
    private router: Router,
    private staffFrontDeskPanelService: StaffFrontDeskPanelService
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/frontdesk/design-common.js");
    this.currentActiveRoute = this.router.url;
    this.getHotelSessionDetail();
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  getHotelSessionDetail(){
    let response = this.staffFrontDeskPanelService.getHotelStaffUserSessionData()
    if(response['status'] == 1){
      this.hotelStaffUserData = response['hotelStaffUserData']
      this.getStaffDetail();
    }
  };

  getStaffDetail(){
    let data = {
      'staff_id': this.hotelStaffUserData['id']
    }
    this.staffFrontDeskPanelService.getHotelStaffUserDetail(data)
      .subscribe(
        response => {
          if(response['status'] == 1) { 
            this.hotelStaffUserData = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };
}