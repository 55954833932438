import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  ViewEncapsulation
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { GuestPanelService } from '../guest-panel.service';

@Component({
  selector: 'app-room-dining-request',
  templateUrl: './room-dining-request.component.html',
  styleUrls: ['./room-dining-request.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RoomDiningRequestComponent implements OnInit {
  webSocket: WebSocketSubject<any>;
  messages: Observable<any>;
  subscription: Subscription;
  hotelGuestUserData: object = {};
  hotelFNBOutletList = [];
  foodItemCategoryList = [];
  foodMenuItemList = [];
  foodCategoryID: number = null;
  errorMessage: string = '';
  errorObj = {}
  currentCartFoodItemList = [];
  orderTotal: number = 0;
  orderTaxRate: number = null;
  orderFinalTotal: number = 0;
  currentSelectedOutlet = {};
  currentSelectedFoodItem = {};
  isFoodItemMenuViewShow: boolean = false;
  isOutletViewShow: boolean = true
  foodItemInstructionObj = {};
  foodItemInstruction:string = '';
  foodItemPosterPicture: string = '';
  @ViewChild('foodRequestCreateModel', { read: ElementRef, static: false }) public foodRequestCreateModel: ElementRef;
  @ViewChild('foodItemDetailModel', { read: ElementRef, static: false }) public foodItemDetailModel: ElementRef;
  constructor(
    private toastr: ToastrService,
    private guestPanelService: GuestPanelService
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/inc/owl-carosuel/owl.carousel.js");
    this.loadJsFile("assets/js/guest/design-common.js");
    this.getGuestSessionDetail();
    this.webSocket = webSocket(environment.webSoketBaseURL + '/hotel-chain/');
    this.webSocket.asObservable().subscribe(dataFromServer => {
      if (dataFromServer['event'] == 'update_food_order_request_status' &&
        dataFromServer['data'] &&
        dataFromServer['data']['stay_id'] == this.hotelGuestUserData['guest_detail']['stay_id']) {
        this.toastr.info('your meal order ' + dataFromServer['data']['order_status_name'].toLowerCase(),
          'Order No: ' + dataFromServer['data']['order_id']
        )
      }
    });
  }

  public loadJsFile(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  getGuestSessionDetail() {
    let response = this.guestPanelService.getHotelGuestUserSessionData()
    if (response['status'] == 1) {
      this.hotelGuestUserData = response['hotelGuestUserData']
      this.getHotelFNBOutletList();
    }
  };

  getFoodMenuCategoryList() {
    this.foodItemCategoryList = [];
    this.guestPanelService.getFoodMenuCategoryList({
      'hotel_id': this.hotelGuestUserData['guest_detail']['hotel_id'],
      'outlet_id': this.currentSelectedOutlet['id']
    })
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.foodItemCategoryList = response['data']
            if (this.foodItemCategoryList && this.foodItemCategoryList.length > 0) {
              this.foodCategoryID = this.foodItemCategoryList[0]['id'];
              this.getFoodItemListByFoodCategory(this.foodCategoryID);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelFNBOutletList() {
    this.guestPanelService.getHotelFNBOutletList({
      'hotel_id': this.hotelGuestUserData['guest_detail']['hotel_id']
    })
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) {
            this.hotelFNBOutletList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getFoodItemListByFoodCategory(foodCategoryID) {
    if (foodCategoryID) {
      this.foodMenuItemList = [];
      this.foodCategoryID = foodCategoryID;
      let data = {
        'hotel_id': this.hotelGuestUserData['guest_detail']['hotel_id'],
        'food_category_id': foodCategoryID,
        'outlet_id': this.currentSelectedOutlet['id']
      }
      this.guestPanelService.getFoodMenuItemListByCategory(data)
        .subscribe(
          response => {
            if (response['status'] == 1 && response['data']) {
              this.foodMenuItemList = response['data']
            }
          },
          error => {
            console.log(error);
          }
        );
    }
  };

  isCurrentFoodItemInFoodItemCart(mealTemObj) {
    if (mealTemObj) {
      if (this.currentCartFoodItemList && this.currentCartFoodItemList.length == 0) {
        return false
      }
      else {
        let currentMealItem = this.currentCartFoodItemList.find(o => o.hotel_food_item_id === mealTemObj['id']);
        const currentMealItemIndex = this.currentCartFoodItemList.indexOf(currentMealItem);
        if (currentMealItemIndex > -1) {
          return true
        }
        else {
          return false
        }
      }
    }
  };

  getFoodItemQuantity(mealTemObj) {
    if (mealTemObj) {
      if (this.currentCartFoodItemList && this.currentCartFoodItemList.length == 0) {
        return ''
      }
      else {
        let currentMealItem = this.currentCartFoodItemList.find(o => o.hotel_food_item_id === mealTemObj['id']);
        const currentMealItemIndex = this.currentCartFoodItemList.indexOf(currentMealItem);
        if (currentMealItemIndex > -1) {
          return currentMealItem['quantity']
        }
        else {
          return ''
        }
      }
    }
  };

  addFoodItemInstruction(){
    let key = this.currentSelectedFoodItem['id']
    this.foodItemInstructionObj[key] = JSON.stringify(this.foodItemInstruction)
  }

  addOrRemoveFoodItemFromCart(mealTemObj, status) {
    if (mealTemObj) {
      if (this.currentCartFoodItemList && this.currentCartFoodItemList.length == 0) {
        this.orderTotal = 0
        this.orderFinalTotal = 0
        let foodItemDetail = {
          'name': mealTemObj['name'],
          'description': mealTemObj['description'],
          'special_instructions': '',
          'hotel_food_item_id': mealTemObj['id'],
          'quantity': 1,
        }
        if ('special_price' in mealTemObj && mealTemObj['special_price']) {
          foodItemDetail['price'] = mealTemObj['special_price']
          foodItemDetail['line_total'] = mealTemObj['special_price'] * foodItemDetail['quantity']
          this.orderTotal = this.orderTotal + foodItemDetail['line_total'];
          this.orderFinalTotal = this.orderTotal + Math.round((this.orderTotal * this.orderTaxRate) / 100)
          this.currentCartFoodItemList.push(foodItemDetail)
        }
        else {
          foodItemDetail['price'] = mealTemObj['price']
          foodItemDetail['line_total'] = mealTemObj['price'] * foodItemDetail['quantity']
          this.orderTotal = this.orderTotal + foodItemDetail['line_total'];
          this.orderFinalTotal = this.orderTotal + Math.round((this.orderTotal * this.orderTaxRate) / 100)
          this.currentCartFoodItemList.push(foodItemDetail)
        }

      }
      else {
        let currentMealItem =
          this.currentCartFoodItemList.find(o => o.hotel_food_item_id === mealTemObj['id']);
        const currentMealItemIndex = this.currentCartFoodItemList.indexOf(currentMealItem);
        if (status == 'add') {
          if (currentMealItemIndex > -1) {
            if ('special_price' in mealTemObj && mealTemObj['special_price']) {
              currentMealItem['quantity'] = currentMealItem['quantity'] + 1;
              currentMealItem['line_total'] = mealTemObj['special_price'] * currentMealItem['quantity'];
              this.orderTotal = this.orderTotal + mealTemObj['special_price'];
              this.orderFinalTotal = this.orderTotal + Math.round((this.orderTotal * this.orderTaxRate) / 100)
              this.currentCartFoodItemList[currentMealItemIndex] = currentMealItem;
            }
            else {
              currentMealItem['quantity'] = currentMealItem['quantity'] + 1;
              currentMealItem['line_total'] = mealTemObj['price'] * currentMealItem['quantity'];
              this.orderTotal = this.orderTotal + mealTemObj['price'];
              this.orderFinalTotal = this.orderTotal + Math.round((this.orderTotal * this.orderTaxRate) / 100)
              this.currentCartFoodItemList[currentMealItemIndex] = currentMealItem;
            }
          }
          else {
            let foodItemDetail = {
              'name': mealTemObj['name'],
              'description': mealTemObj['description'],
              'special_instructions': '',
              'hotel_food_item_id': mealTemObj['id'],
              'quantity': 1,
            }
            if ('special_price' in mealTemObj && mealTemObj['special_price']) {
              foodItemDetail['price'] = mealTemObj['special_price']
              foodItemDetail['line_total'] = mealTemObj['special_price'] * foodItemDetail['quantity']
              this.orderTotal = this.orderTotal + foodItemDetail['line_total'];
              this.orderFinalTotal = this.orderTotal + Math.round((this.orderTotal * this.orderTaxRate) / 100)
              this.currentCartFoodItemList.push(foodItemDetail)
            }
            else {
              foodItemDetail['price'] = mealTemObj['price']
              foodItemDetail['line_total'] = mealTemObj['price'] * foodItemDetail['quantity']
              this.orderTotal = this.orderTotal + foodItemDetail['line_total'];
              this.orderFinalTotal = this.orderTotal + Math.round((this.orderTotal * this.orderTaxRate) / 100)
              this.currentCartFoodItemList.push(foodItemDetail)
            }
          }
        }
        else if (status == 'remove') {
          if (currentMealItem['quantity'] - 1 == 0) {
            if (currentMealItemIndex > -1) {
              if ('special_price' in mealTemObj && mealTemObj['special_price']) {
                this.orderTotal = this.orderTotal - mealTemObj['special_price'];
                this.orderFinalTotal = this.orderTotal - Math.round((this.orderTotal * this.orderTaxRate) / 100)
                this.currentCartFoodItemList.splice(currentMealItemIndex, 1);
              }
              else {
                this.orderTotal = this.orderTotal - mealTemObj['price'];
                this.orderFinalTotal = this.orderTotal - Math.round((this.orderTotal * this.orderTaxRate) / 100)
                this.currentCartFoodItemList.splice(currentMealItemIndex, 1);
              }
            }
          }
          else {
            if ('special_price' in mealTemObj && mealTemObj['special_price']) {
              currentMealItem['quantity'] = currentMealItem['quantity'] - 1;
              currentMealItem['line_total'] = mealTemObj['special_price'] * currentMealItem['quantity'];
              this.orderTotal = this.orderTotal - mealTemObj['special_price'];
              this.orderFinalTotal = this.orderTotal - Math.round((this.orderTotal * this.orderTaxRate) / 100)
              this.currentCartFoodItemList[currentMealItemIndex] = currentMealItem;
            }
            else {
              currentMealItem['quantity'] = currentMealItem['quantity'] - 1;
              currentMealItem['line_total'] = mealTemObj['price'] * currentMealItem['quantity'];
              this.orderTotal = this.orderTotal - mealTemObj['price'];
              this.orderFinalTotal = this.orderTotal - Math.round((this.orderTotal * this.orderTaxRate) / 100)
              this.currentCartFoodItemList[currentMealItemIndex] = currentMealItem;
            }
          }
        }
      }
    }
  };

  placeFoodOrder() {
    for(let k=0; k<=this.currentCartFoodItemList.length -1 ; k++){
      let foodItemID = this.currentCartFoodItemList[k]['hotel_food_item_id']
      if(foodItemID in this.foodItemInstructionObj){
        this.currentCartFoodItemList[k]['special_instructions'] = this.foodItemInstructionObj[foodItemID]
      }
    }

    let data = {
      'stay_id': this.hotelGuestUserData['guest_detail']['stay_id'],
      'outlet_id': this.currentSelectedOutlet['id'],
      'food_ird_items': this.currentCartFoodItemList,
      'total': this.orderTotal,
      'tax': this.orderTaxRate,
      'final_total': this.orderFinalTotal,
      'order_time_timestamp': Math.round(+new Date() / 1000)
    }
    this.guestPanelService.placeFoodOrderRequest(data)
      .subscribe(
        response => {
          if (response['status'] == 1) {
            this.currentCartFoodItemList = []
            this.orderTotal = 0
            this.orderFinalTotal = 0
            this.foodRequestCreateModel.nativeElement.click();
            this.toastr.success(response['msg'])
          }
          else {
            this.foodRequestCreateModel.nativeElement.click();
            this.toastr.error(response['msg'])
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  selectOutlet(currentOutlet){
    this.loadJsFile("assets/js/guest/design-common.js");
    if(currentOutlet){
      this.currentSelectedOutlet = currentOutlet;
      this.orderTaxRate = this.currentSelectedOutlet['tax']
      this.getFoodMenuCategoryList();
      this.isOutletViewShow = false;
    }
  };

  selectedCurrentFoodItem(currentFoodMenuItem){
    this.foodItemPosterPicture = '';
    this.loadJsFile("assets/inc/owl-carosuel/owl.carousel.js");
    this.loadJsFile("assets/js/guest/design-common.js");
    this.foodItemInstruction = '';
    if(currentFoodMenuItem){
      this.foodItemPosterPicture = currentFoodMenuItem.hotel_menu_item_media_files[0].url;
      this.currentSelectedFoodItem = currentFoodMenuItem;
    }
    else{
      this.currentSelectedFoodItem = {};
    }
  };

}
