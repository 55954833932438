import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  NgZone,
  ViewEncapsulation
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router"
import { HotelAdminPanelService } from '../hotel-admin-panel.service';

@Component({
  selector: 'app-room-service-management',
  templateUrl: './room-service-management.component.html',
  styleUrls: ['./room-service-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RoomServiceManagementComponent implements OnInit {
  @ViewChild('roomServiceCreateModel', { read: ElementRef, static:false }) public roomServiceCreateModel: ElementRef;
  @ViewChild('roomServiceUpdateModel', { read: ElementRef, static:false }) public roomServiceUpdateModel: ElementRef;
  @ViewChild('roomServiceRemoveModel', { read: ElementRef, static:false }) public roomServiceRemoveModel: ElementRef;
  currentHotelAdminUserData = {};
  globalRoomServiceList = [];
  globalUnitOfMeasureList = [];
  hotelRoomServiceList = [];
  currentSelectedRoomService = {};
  currentHotelRoomServiceObj:object = {}
  errorMessage: string = '';
  isChargeable: boolean = false;
  unitOfMeasureID: number = null;
  charge: number = null;
  tax: number = null;
  totalTime: number = null;
  isRoomServiceExits: boolean = true
  description: string;
  errorObj = {}

  constructor(
    private toastr: ToastrService,
    private hotelAdminPanelService: HotelAdminPanelService,
  ) { }

  ngOnInit() {
    this.loadJsFile("assets/js/admin/design-common.js");
    this.getHotelAdminUserSessionDetail();
  }

  public loadJsFile(url) {  
    let node = document.createElement('script');  
    node.src = url;  
    node.type = 'text/javascript';  
    document.getElementsByTagName('body')[0].appendChild(node);  
  }

  getHotelAdminUserSessionDetail(){
    let response = this.hotelAdminPanelService.getHotelAdminUserSessionData()
    if(response['status'] == 1){
      this.currentHotelAdminUserData = response['hotelUserData']
      this.getGlobalRoomServiceList();
      this.getHotelRoomServiceList();
      this.getGlobalUnitOfMeasureList();
    }
  };

  getGlobalUnitOfMeasureList(){
    this.hotelAdminPanelService.getGlobalUnitOfMeasureList({})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.globalUnitOfMeasureList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getGlobalRoomServiceList(){
    this.hotelAdminPanelService.getGlobalRoomServiceList({})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.globalRoomServiceList = response['data']
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  getHotelRoomServiceList(){
    this.hotelRoomServiceList = [];
    this.hotelAdminPanelService.getHotelRoomServiceList({'hotel_id': this.currentHotelAdminUserData['hotel_id']})
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.hotelRoomServiceList = response['data']
            if(this.hotelRoomServiceList && this.hotelRoomServiceList.length > 0){
              this.globalRoomServiceList = this.globalRoomServiceList.filter(k=>!this.hotelRoomServiceList.some(p=>p.name==k.name));
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  globalRoomServiceSelection(roomService){
    this.isRoomServiceExits = true;
    this.currentSelectedRoomService = {};
    if(roomService && 'name' in roomService){
      this.currentSelectedRoomService = roomService;
      let currentSelectedRoomServiceObj = 
        this.hotelRoomServiceList.find(o => o.name === roomService['name']);
      const index = this.hotelRoomServiceList.indexOf(currentSelectedRoomServiceObj);
      if (index > -1) {
        this.isRoomServiceExits = true;
      }
      else{
        this.isRoomServiceExits = false;
      }
    }
  };
  
  currentRoomServiceExits(){
    if(this.isRoomServiceExits == true && 'name' in this.currentSelectedRoomService){
      this.toastr.error("Room Service already exists", this.currentSelectedRoomService['name'])
    }
    else{
      this.toastr.error("Please select global room service")
    }
  }

  clearRoomServiceData(){
    this.isChargeable = false;
    this.unitOfMeasureID = null;
    this.charge = null;
    this.tax = null;
    this.totalTime =  null;
    this.description = '';
    this.errorMessage =  '';
    this.errorObj = {};
    this.isRoomServiceExits = true;
    this.roomServiceCreateModel.nativeElement.click();
  };

  isRoomServiceChargeable(event) {
    if (event.target.checked) {
      this.isChargeable = true;
    }
    else{
      this.isChargeable = false;
      this.charge = null;
      this.tax = null;
      if(this.currentHotelRoomServiceObj && 'charge' in this.currentHotelRoomServiceObj){
        this.currentHotelRoomServiceObj['charge'] = null;
        this.currentHotelRoomServiceObj['tax'] = null;
      }
    }
  }

  createRoomService(){
    let data = {
      'hotel_id': this.currentHotelAdminUserData['hotel_id'],
      'name': this.currentSelectedRoomService['name'],
    }
    if(this.currentSelectedRoomService['name'] == 'Food & Beverages'){
      data['global_unit_of_measures_id'] = this.globalUnitOfMeasureList[0]['id'];
      data['charge'] = 0;
      data['is_chargeable'] = false;
      data['total_tat'] = 0;
    }
    else{
      data['global_unit_of_measures_id'] = this.unitOfMeasureID;
      data['is_chargeable'] = this.isChargeable;
      data['total_tat'] = this.totalTime;
    }
    if(this.description != ''){
      data['description'] = this.description
    }
    if(this.isChargeable == true){
      data['charge'] = this.charge;
      data['tax'] = this.tax;
    }
    this.hotelAdminPanelService.createHotelRoomService(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.clearRoomServiceData();
            this.currentSelectedRoomService = {};
            this.toastr.success(response['msg'], response['data']['name'])
            this.getHotelRoomServiceList();
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  showRoomServiceUpdateData(data){
    this.currentHotelRoomServiceObj = {};
    this.errorObj = {};
    this.currentHotelRoomServiceObj = JSON.parse(JSON.stringify(data));
    if(this.currentHotelRoomServiceObj['is_chargeable'] == 'Yes'){
      this.isChargeable = true;
    }
    else{
      this.isChargeable = false;
    }
  };

  updateRoomService(){
    let data = {
      'room_service_id': this.currentHotelRoomServiceObj['id'],
      'name': this.currentHotelRoomServiceObj['name'],
      'global_unit_of_measures_id': this.currentHotelRoomServiceObj['global_unit_of_measures'],
      'is_chargeable': this.isChargeable,
      'total_tat': this.currentHotelRoomServiceObj['total_tat'],
    }
    if(this.currentHotelRoomServiceObj['description'] != ''){
      data['description'] = this.currentHotelRoomServiceObj['description'];
    }
    if(this.isChargeable == true){
      data['charge'] = this.currentHotelRoomServiceObj['charge'];
      data['tax'] = this.currentHotelRoomServiceObj['tax'];
    }
    this.hotelAdminPanelService.updateHotelRoomService(data)
      .subscribe(
        response => {
          if (response['status'] == 1 && response['data']) { 
            this.currentHotelRoomServiceObj = {};
            this.errorObj = {};
            this.isChargeable = false;
            this.getHotelRoomServiceList();
            this.roomServiceUpdateModel.nativeElement.click();
            this.toastr.success(response['msg'], response['data']['name'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.errorObj = this.hotelAdminPanelService.getErrorResponse(response['msg'])
              console.log(this.errorObj);
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };

  removeRoomService(){
    let data = {
      'room_service_id': this.currentHotelRoomServiceObj['id'],
    }
    this.hotelAdminPanelService.removeHotelRoomService(data)
      .subscribe(
        response => {
          if (response['status'] == 1) { 
            this.currentHotelRoomServiceObj = {};
            this.errorObj = {};
            this.getGlobalRoomServiceList();
            this.getHotelRoomServiceList();
            this.roomServiceRemoveModel.nativeElement.click();
            this.toastr.success(response['msg'])
          }
          else {
            if (response['msg'] && response['status'] == 0) {
              this.roomServiceRemoveModel.nativeElement.click();
              this.toastr.error(response['msg'])

            }
          }
        },
        error => {
          console.log(error);
        }
      );
  };
}
