import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import { StaffFrontDeskPanelService } from '../../staff-front-desk-panel.service';

@Component({
  selector: 'app-staff-panel-setting-left-navigation',
  templateUrl: './staff-panel-setting-left-navigation.component.html',
  styleUrls: ['./staff-panel-setting-left-navigation.component.css']
})
export class StaffPanelSettingLeftNavigationComponent implements OnInit {

  hotelStaffUserData = {};
  currentActiveRoute: string

  constructor(
    private router: Router,
    private staffFrontDeskPanelService: StaffFrontDeskPanelService
  ) { }

  ngOnInit() {
    this.currentActiveRoute = this.router.url;
    this.getHotelSessionDetail();
  }

  getHotelSessionDetail(){
    let response = this.staffFrontDeskPanelService.getHotelStaffUserSessionData()
    if(response['status'] == 1){
      this.hotelStaffUserData = response['hotelStaffUserData']
    }
  };

}
